import React, { useState, useEffect } from "react";
import CreateAxiosInstance from "../../AxiosInstance/AxiosInstance";
import CreateS3AxiosInstance from "../../AxiosInstance/s3AxiosInstance";
import { set } from "mobx";

export default function ProducePackerActivityCreator() {
	const axios = CreateAxiosInstance();
	const s3Axios = CreateS3AxiosInstance();

	const characterList = [
		"Anthony",
		"Bill",
		"Chris",
		"Dani",
		"Emily",
		"Francisco",
		"Gabi",
		"Harold",
		"Justin",
		"Kai",
		"Luis",
		"Madison",
		"Natalie",
		"Olivia",
		"Rebecca",
		"Sophia",
		"Tae",
		"Zach",
	];

	// Default JSON state
	const initialJson = {
		1: {
			difficultyScore: 900,
			passThreshold: 0.7,
			layout: {},
			orderData: [
				{
					equationId: 1,
					produceA: "oranges",
					produceB: "apples",
					producePriceA: 1,
					producePriceB: 2,
					budget: 8,
					spaceConstraint: 18,
					spaceConstraintType: "static",
				},
				{
					equationId: 2,
					produceA: "oranges",
					produceB: "apples",
					producePriceA: 1,
					producePriceB: 1,
					budget: 5,
					spaceConstraint: 18,
					spaceConstraintType: "static",
				},
			],
			studentData: [
				{
					equationId: 1,
					producePriceAType: "static",
					producePriceA: 1,
					producePriceBType: "static",
					producePriceB: 2,
					budgetType: "static",
					budget: 8,
				},
				{
					equationId: 2,
					producePriceAType: "static",
					producePriceA: 1,
					producePriceBType: "static",
					producePriceB: 1,
					budgetType: "static",
					budget: 5,
				},
			],
			characterRoster: characterList,
		},
	};

	const [json, setJson] = useState(initialJson);
	const [result, setResult] = useState(null);
	const [activityId, setActivityId] = useState("1");
	const [availibleSpace, setAvailibleSpace] = useState(null);
	// Use effect to fetch the content json from s3
	useEffect(() => {
		const fetchContentJson = async () => {
			try {
				const preSignedUrl = await axios.get("/activityContent?projectName=Math Arcade&gameName=Produce Packer");
				const response = await s3Axios.get(preSignedUrl.data);
				setActivityId(Object.keys(response.data)[0]);
				setJson(response.data);
				setAvailibleSpace(response.data[activityId].orderData[0]?.spaceConstraint ?? 18);
			} catch (error) {
				console.error("Error fetching content:", error);
			}
		};

		fetchContentJson();
	}, []);

	// List of fruits which user can select from
	const fruitList = [
		"apples",
		"avocados",
		"broccoli",
		"canteloupes",
		"carrots",
		"cucumbers",
		"eggs",
		"honey",
		"jam",
		"lemons",
		"limes",
		"oranges",
		"peaches",
		"peppers",
		"plums",
		"radishes",
		"strawberries",
		"tomatoes",
		"zucchinis",
	];

	// List of characters which user can select from

	// Function to clear the JSON state
	const clearJsonState = () => {
		setActivityId("1");
		setJson(initialJson);
	};

	// Function to solve the simultaneous equations
	const handleSolveEquations = () => {
		// Check if selected orders first
		const orderIds = handleOrderSelect();
		if (orderIds.length === 0) {
			alert("No orders selected");
			return;
		}
		if (orderIds.length !== 2) {
			alert("Only 2 orders can be selected");
			return;
		}

		const orderData = {};
		orderData.equation1 = json[activityId]?.orderData?.find(order => order.equationId.toString() === orderIds[0]);
		orderData.equation2 = json[activityId]?.orderData?.find(order => order.equationId.toString() === orderIds[1]);
		console.log("order data", orderData);
		if (!orderData.equation1 || !orderData.equation2) {
			alert("Invalid order selection");
			return;
		}

		const resulttest = solveSimultaneous2D(orderData);
		setResult(resulttest);
	};

	/**
	 * Solve 2 simultaneous equations in 2D
	 * @param {Object} orderData | contains data of 2 simultaneous equations to be solved
	 * @returns { x: x_variable, y: y_variable, equation1, equation2 } | returns the solution to the 2 equations
	 */
	const solveSimultaneous2D = orderData => {
		console.log(orderData);
		// Extract equation attributes from orderData
		const equations = {
			Eq1_x: orderData.equation1.producePriceA,
			Eq1_y: orderData.equation1.producePriceB,
			Eq1_const: orderData.equation1.budget,
			Eq2_x: orderData.equation2.producePriceA,
			Eq2_y: orderData.equation2.producePriceB,
			Eq2_const: orderData.equation2.budget,
		};

		// Form the equations in ax + by + c = 0 format
		const equation1 = `${equations.Eq1_x}x + ${equations.Eq1_y}y = ${equations.Eq1_const}`;
		const equation2 = `${equations.Eq2_x}x + ${equations.Eq2_y}y = ${equations.Eq2_const}`;

		// STEP 1:
		const x_coeff = [equations.Eq1_x, equations.Eq2_x];
		const y_coeff = [equations.Eq1_y, equations.Eq2_y];
		const const_coeff = [equations.Eq1_const, equations.Eq2_const];

		const eliminator = [];
		eliminator[0] = [];
		eliminator[1] = [];
		let x_variable;
		let y_variable;

		// STEP 2:
		eliminator[0][0] = y_coeff[1] * x_coeff[0];
		eliminator[0][1] = y_coeff[1] * const_coeff[0];

		// STEP 3:
		eliminator[1][0] = y_coeff[0] * x_coeff[1];
		eliminator[1][1] = y_coeff[0] * const_coeff[1];

		try {
			// STEPS 4, 5:
			x_variable = (eliminator[0][1] - eliminator[1][1]) / (eliminator[0][0] - eliminator[1][0]);

			// STEP 6:
			y_variable = (const_coeff[0] - x_coeff[0] * x_variable) / y_coeff[0];

			return { x: x_variable, y: y_variable, equation1, equation2 };
		} catch (ex) {
			console.error("Error: ", ex);
		}
	};

	// Handle change in JSON state
	const handleChange = (path, value) => {
		console.log(path, value);
		console.log("handelChange");
		const keys = path.split(".");
		const newJson = { ...json };
		let current = newJson[activityId];
		for (let i = 0; i < keys.length - 1; i++) {
			current = current[keys[i]];
		}
		current[keys[keys.length - 1]] = value;
		setJson(newJson);
		console.log(newJson);
	};

	// Add new order to orderData and studentData
	const addNewOrder = () => {
		// generate new equationId
		let newEquationId = 1;
		for (let index = 0; index < json[activityId].orderData.length; index++) {
			const element = json[activityId].orderData[index];
			if (element.equationId >= newEquationId) {
				newEquationId = element.equationId + 1;
			}
		}

		const newOrder = {
			equationId: newEquationId,
			produceA: "oranges",
			produceB: "apples",
			producePriceA: 1,
			producePriceB: 1,
			budget: 5,
			spaceConstraint: availibleSpace,
			spaceConstraintType: json[activityId].orderData[0]?.spaceConstraintType ?? "static",
		};
		const newJson = { ...json };
		newJson[activityId].orderData.push(newOrder);

		// create new student data
		const newStudent = {
			equationId: newEquationId,
			producePriceAType: json[activityId].studentData[0]?.producePriceAType ?? "static",
			producePriceA: 1,
			producePriceBType: json[activityId].studentData[0]?.producePriceBType ?? "static",
			producePriceB: 1,
			budgetType: json[activityId].studentData[0]?.budgetType ?? "static",
			budget: 5,
		};
		newJson[activityId].studentData.push(newStudent);

		setJson(newJson);
	};

	// Queries checkboxes and returns selected order ids, used for deleting orders or solving equations
	const handleOrderSelect = () => {
		const orderIds = [];
		// get list of all checkboxes
		const checkboxes = document.getElementsByName("OrderSelect");
		// loop through all checkboxes
		for (let i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked) {
				orderIds.push(checkboxes[i].id.split("orderCheckbox")[1]); // get the id of the checkbox
			}
		}
		return orderIds;
	};

	// Delete selected orders
	const deleteSelectedOrders = () => {
		//get ids of selected orders
		const orderIds = handleOrderSelect();
		const newJson = { ...json };
		// loop through all selected orders
		for (let i = 0; i < orderIds.length; i++) {
			// remove order from orderData
			newJson[activityId].orderData = newJson[activityId].orderData.filter(
				order => order.equationId !== Number.parseInt(orderIds[i]),
			);
			// remove order from studentData
			newJson[activityId].studentData = newJson[activityId].studentData.filter(
				student => student.equationId !== Number.parseInt(orderIds[i]),
			);
		}
		setJson(newJson);
	};

	const createNewActivity = () => {
		// Get the last key in the json object
		const keys = Object.keys(json);
		const lastKey = keys.length > 0 ? keys[keys.length - 1] : "0"; // Default to "0" if no keys exist
		const newKey = Number.parseInt(lastKey) + 1;

		const newJson = { ...json };
		newJson[newKey] = initialJson[1];
		setJson(newJson);
		setActivityId(newKey.toString());
	};

	// Function which update the space constraint in all the orders
	const updateSpaceconstraint = value => {
		const newJson = { ...json };
		newJson[activityId].orderData.forEach(order => {
			order.spaceConstraint = value;
		});
		setJson(newJson);
		setAvailibleSpace(value);
	};

	// Function which update the space constraint type in all the orders
	const updateSpaceConstraintType = value => {
		const newJson = { ...json };
		newJson[activityId].orderData.forEach(order => {
			order.spaceConstraintType = value;
		});
		setJson(newJson);
	};

	// Function which update the type of order attribute for all orders
	const updateOrderTypes = (type, value) => {
		const newJson = { ...json };
		newJson[activityId].studentData.forEach(order => {
			order[type] = value;
		});
		setJson(newJson);
	};

	useEffect(() => {
		console.log(json);
	}, [json]);

	return (
		<div className="min-h-[100%] bg-slate-200 pb-20">
			{/* Header */}
			<div className="sticky  top-0 p-5 z-10 border-b-2 bg-slate-300 border-slate-500">
				<h1 className="text-center text-4xl">Produce Packer Activity Creator</h1>
				<div className="flex flex-col gap-2 text-center justify-center p-5">
					<div className="flex gap-4 items-center justify-center w-[100%]">
						{/* Print to console button */}
						<button
							className="bg-blue-500 hover:bg-blue-200 p-2 rounded-md"
							type="button"
							onClick={() => console.log(json)}
						>
							Print JSON to Console
						</button>
						{/* Copy json to clipboard button */}
						<button
							className="bg-blue-500 hover:bg-blue-200 p-2 rounded-md"
							type="button"
							onClick={() => navigator.clipboard.writeText(JSON.stringify(json, null, 4))}
						>
							Copy JSON to clipboard
						</button>
						{/* Clear json state button */}
						<button className="bg-red-500 hover:bg-red-200 p-2 rounded-md" type="button" onClick={clearJsonState}>
							Clear JSON State
						</button>
						{/* Solve equations button */}
						<button
							className="bg-blue-500 hover:bg-blue-200 p-2 rounded-md"
							type="button"
							onClick={handleSolveEquations}
						>
							Solve Equations
						</button>
						{/* Save json to s3 button */}
						<button
							type="button"
							className="bg-green-500 hover:bg-green-200 p-2 rounded-md"
							onClick={() => {
								if (window.confirm("Are you sure you want to save? This will overwrite the existing data.")) {
									axios.put("/activityContent?projectName=Math Arcade&gameName=Produce Packer", json);
								}
							}}
						>
							Save JSON to S3
						</button>
					</div>

					{/* Results from simulataeous equations */}
					{result && (
						<div className="">
							<h3 className="font-bold">Results</h3>
							<div className="flex gap-4 justify-center">
								<div>
									<p> {result.equation1}</p>
									<p> {result.equation2}</p>
								</div>
								<div>
									<p>x: {result.x}</p>
									<p>y: {result.y}</p>
								</div>
								<button className="bg-blue-500 hover:bg-blue-200 p-2 rounded-lg" onClick={() => setResult(null)}>
									Close
								</button>
							</div>
						</div>
					)}
				</div>
				<div className="flex gap-4 items-center">
					<label>Activity Select (Difficulty Score, ActivityID):</label>
					<select
						className="produce-packer-activity-creator-input-dropdown"
						value={activityId}
						onChange={e => setActivityId(e.target.value)}
					>
						{Object.keys(json).map(activity => (
							<option key={activity} value={activity}>
								{`${json[activity].difficultyScore} , ${activity}`}
							</option>
						))}
					</select>
					<button type="button" onClick={createNewActivity} className="bg-blue-500 hover:bg-blue-200 p-2 rounded-md">
						New Activity
					</button>
				</div>
			</div>

			<div className="px-10">
				{/* Activity Overview */}
				<div>
					<label className="text-2xl font-bold">Activity ID: {activityId}</label>
				</div>

				<div className="flex gap-4 items-center py-1">
					<label className="min-w-[260px]">Difficulty Score (Proficiency Score):</label>
					<input
						className="rounded-lg text-center"
						type="number"
						value={json[activityId].difficultyScore}
						onChange={e => handleChange("difficultyScore", Number.parseInt(e.target.value))}
					/>
				</div>

				<div className="flex gap-4 items-center py-1">
					<label className="min-w-[260px]">Pass Threshold: </label>
					<input
						className="rounded-lg text-center"
						type="number"
						min={0}
						step={0.01}
						max={1}
						value={json[activityId].passThreshold}
						onChange={e => handleChange("passThreshold", Number.parseFloat(e.target.value))}
					/>
				</div>

				{/* Space equation constraint */}
				<div>
					<p className="text-xl pt-5 pb-2 font-bold"> Space Equation</p>
					<div className="flex gap-4">
						<label>Available Space: </label>
						<input
							className="rounded-lg text-center"
							type="number"
							min={0}
							step={1}
							max={18}
							value={json[activityId].orderData[0]?.spaceConstraint ?? 18}
							onChange={e => updateSpaceconstraint(e.target.value)}
						/>
					</div>
				</div>

				{/* Orders */}
				<div>
					<h1 className="text-xl pb-2 pt-5 font-bold">Order Data</h1>
					{/* Add and delte order buttons */}
					<div className="flex gap-4">
						<button
							type="button"
							onClick={() => {
								deleteSelectedOrders();
							}}
							className="bg-red-500 hover:bg-red-200 p-2 rounded-md"
						>
							Delete Selected Orders
						</button>
						<button
							type="button"
							className="bg-blue-500 hover:bg-blue-200 p-2 rounded-md"
							onClick={() => {
								addNewOrder();
							}}
						>
							Add Order
						</button>
					</div>
					{/* Order data */}
					{json[activityId].orderData.map((order, index) => (
						<div key={order.equationId}>
							<h3 className="text-lg pt-4">Order Data - Equation {order.equationId}</h3>
							{/* Order select */}
							<div className="pl-5">
								<div className="flex gap-4 items-center">
									<label htmlFor={`checkbox${order.equationId}`} className="min-w-[150px]">
										Selected
									</label>
									<input
										className="w-5 h-5 rounded-lg"
										type="checkbox"
										id={`orderCheckbox${order.equationId}`}
										name="OrderSelect"
										value="OrderSelect"
									/>
								</div>
								{/* Produce A */}
								<div className="flex gap-4 py-1 items-center">
									<label className="min-w-[150px]">Produce A: </label>
									<select
										className="rounded-lg text-center"
										value={order.produceA}
										onChange={e => handleChange(`orderData.${index}.produceA`, e.target.value)}
									>
										{fruitList.map((fruit, index) => (
											<option key={index} value={fruit}>
												{fruit}
											</option>
										))}
									</select>
								</div>
								{/* Produce B */}
								<div className="flex gap-4 py-1 items-center">
									<label className="min-w-[150px]">Produce B: </label>
									<select
										className="rounded-lg text-center"
										value={order.produceB}
										onChange={e => handleChange(`orderData.${index}.produceB`, e.target.value)}
									>
										{fruitList.map((fruit, index) => (
											<option key={index} value={fruit}>
												{fruit}
											</option>
										))}
									</select>
									{order.produceA === order.produceB && (
										<span className="text-red-500 ml-2">Produce A and Produce B cannot be the same</span>
									)}
								</div>
								{/* Produce Price A */}
								<div className="flex gap-4 py-1 items-center">
									<label className="min-w-[150px]">Produce Price A: </label>
									<input
										className="rounded-lg text-center"
										type="number"
										value={order.producePriceA}
										onChange={e => {
											handleChange(`orderData.${index}.producePriceA`, e.target.value);
											handleChange(`studentData.${index}.producePriceA`, e.target.value);
										}}
									/>
								</div>
								{/* Produce Price B */}
								<div className="flex gap-4 py-1 items-center">
									<label className="min-w-[150px]">Produce Price B: </label>
									<input
										className="rounded-lg text-center"
										type="number"
										value={order.producePriceB}
										onChange={e => {
											handleChange(`orderData.${index}.producePriceB`, e.target.value);
											handleChange(`studentData.${index}.producePriceB`, e.target.value);
										}}
									/>
								</div>
								{/* Budget */}
								<div className="flex gap-4 py-1 items-center">
									<label className="min-w-[150px]">Budget: </label>
									<input
										className="rounded-lg text-center"
										type="number"
										value={order.budget}
										onChange={e => {
											handleChange(`orderData.${index}.budget`, e.target.value);
											handleChange(`studentData.${index}.budget`, e.target.value);
										}}
									/>
								</div>
							</div>
						</div>
					))}
				</div>
				{/* Equation Types */}
				<div className="flex flex-col ">
					<h1 className="text-xl font-bold pt-5 pb-2">Equation Types</h1>
					{/* Produce price A type */}
					<div className="flex gap-4 py-1">
						<label className="min-w-[170px]">Produce A Price Type: </label>
						<select
							className="rounded-lg text-center"
							value={json[activityId].studentData[0]?.producePriceAType}
							onChange={e => updateOrderTypes("producePriceAType", e.target.value)}
						>
							<option value="static">Static</option>
							<option value="dynamic">Dynamic</option>
						</select>
					</div>
					{/* Produce price B type */}
					<div className="flex gap-4 py-1">
						<label className="min-w-[170px]">Produce B Price Type: </label>
						<select
							className="rounded-lg text-center"
							value={json[activityId].studentData[0]?.producePriceBType}
							onChange={e => updateOrderTypes("producePriceBType", e.target.value)}
						>
							<option value="static">Static</option>
							<option value="dynamic">Dynamic</option>
						</select>
					</div>
					{/* Budget type */}
					<div className="flex gap-4 py-1">
						<label className="min-w-[170px]">Budget Type: </label>
						<select
							className="rounded-lg text-center"
							value={json[activityId].studentData[0]?.budgetType}
							onChange={e => updateOrderTypes("budgetType", e.target.value)}
						>
							<option value="static">Static</option>
							<option value="dynamic">Dynamic</option>
						</select>
					</div>
					{/* Space constraint type */}
					<div className="flex gap-4 py-1">
						<label className="min-w-[170px]">Space Constraint Type: </label>
						<select
							className="rounded-lg text-center"
							value={json[activityId].orderData[0]?.spaceConstraintType}
							onChange={e => updateSpaceConstraintType(e.target.value)}
						>
							<option value="static">Static</option>
							<option value="dynamic">Dynamic</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	);
}
