import InfoItem from "./InfoItem";

export default function DevInfo({ data }) {
	return (
		<div className="absolute w-[10%] min-w-[180px] z-10 right-[6rem]">
			{Object.entries(data).map(([key, value]) => (
				<InfoItem key={key} infoName={key} info={value} />
			))}
		</div>
	);
}
