import { useEffect, useState } from "react";

export default function InfoItem({ info, infoName }) {
    // State to keep track of the color intensity
    const [intensity, setIntensity] = useState(0); // Start with a light color

    // Update the intensity each time info changes
    useEffect(() => {
        setIntensity(prevIntensity => Math.min(prevIntensity + 100, 255)); // Increase intensity, max out at 255
    }, [info]);

    // Compute the RGB color based on the intensity
    const baseColor = 0; // Base color component value
    const color = `${Math.min(baseColor + intensity, 255)}, ${Math.min(baseColor + intensity, 255)}, ${Math.min(baseColor + intensity, 255)}`;

    return (
        <p style={{ backgroundColor: `rgb(${color})` }}>{infoName}: {info}</p>
    );
}