import { useEffect, useState } from "react";
import { NotesIcon, EquationsIcon, GraphIcon, OrdersIcon } from "./SVGIcons";
import { blackAndWhite } from "./colors";

/**
 * Component for the App Dashboard
 * @param {Object} props - the props object
 * @param {Function} props.setCurrentScreen - the function to set the current screen
 * @param {Integer} props.acceptedOrders - the number of accepted orders
 * @param {Object} props.accessibilitySettings - the accessibility settings
 * @param {Function} props.playSound - the function to play a sound
 * @param {Boolean} props.resetActiveApp - the boolean to reset the active app
 * @param {Function} props.setResetActiveApp - the function to set the reset active app
 * @returns
 * */
function AppDashboard({
	setCurrentScreen,
	acceptedOrders,
	accessibilitySettings,
	playSound,
	resetActiveApp,
	setResetActiveApp,
}) {
	const [activeApp, setActiveApp] = useState("orders"); // State variable for denoting active app - default is the orders app

	/**
	 * Function to handle when an app is clicked
	 * @param {String} appName - name of the app which has been clicked on
	 * @param {Boolean} ignoreClickSound - boolean to determine if the click sound should be played
	 * @returns
	 */
	const handleAppClick = (appName, ignoreClickSound = false) => {
		// If the user tries to open an app other than the orders app and the orders have not been accepted, return
		if (appName !== "orders" && acceptedOrders < 1) {
			handleNotAllowedClick();
			return;
		}

		if (!ignoreClickSound) playSound("click");
		setActiveApp(appName);
		setCurrentScreen(appName);

		setTimeout(focusOnTopElement, 0); // Needs to be in timeout to let page rerender
	};

	/**
	 * Function to handle when the mouse is released from an app
	 */
	const handleAppMouseUp = () => setActiveApp(null);

	/**
	 * Function to handle when the orders app is clicked
	 */
	const handleNotAllowedClick = () => {
		console.log("Can't open this app until 2 orders have been accepted");
	};

	/**
	 * Function to focus on the top element of the screen
	 * This is used to focus on the first element of the screen when an app is opened
	 * This is used to ensure that the screen is accessible to screen readers
	 * and keyboard users
	 */
	const focusOnTopElement = () => {
		const topElement = document
			.getElementById("phone--app--container")
			.querySelectorAll("button, input, select, textarea, a, canvas, [tabIndex='0']");

		if (topElement[0]) topElement[0].focus();
	};

	/**
	 * Function to handle when the orders app is clicked
	 */
	useEffect(() => {
		if (resetActiveApp) {
			handleAppClick("orders", true);
			setResetActiveApp(false);
		}
	}, [resetActiveApp]);

	// Array of apps to be displayed on the dashboard
	const apps = [
		{
			label: "Orders",
			handleClick: () => handleAppClick("orders"),
			handleMouseUp: handleAppMouseUp,
			Icon: OrdersIcon,
			id: "orders",
		},
		{
			label: "Equations",
			handleClick: () => handleAppClick("equations"),
			handleMouseUp: handleAppMouseUp,
			Icon: EquationsIcon,
			id: "equations",
		},
		{
			label: "Graph",
			handleClick: () => handleAppClick("graph"),
			handleMouseUp: handleAppMouseUp,
			Icon: GraphIcon,
			id: "graph",
		},
		{
			label: "Notes",
			handleClick: () => handleAppClick("notes"),
			handleMouseUp: handleAppMouseUp,
			Icon: NotesIcon,
			id: "notes",
		},
	];

	// Map the apps to the buttons
	const appComponents = apps.map(app => {
		return (
			<button
				key={app.label}
				type="button"
				id={app.label.toLowerCase()}
				className="w-[3.814rem] min-w-fit flex flex-col justify-center items-center gap-[.25rem]"
				onClick={app.handleClick}
				tabIndex={acceptedOrders === 1 || app.label === "Orders" ? 0 : -1}
				aria-label={`Open ${app.label} App`}
			>
				<div
					className="text-base font-bold leading-normal not-italic bg-white"
					style={{ border: activeApp === app.id ? "4px solid white" : "0px", borderRadius: "10px" }}
				>
					{/* Icon */}
					<app.Icon
						// Handling styling for the icon for dark mode and also if the app is the active app
						color={
							accessibilitySettings?.display?.blackAndWhite || (acceptedOrders !== 1 && app.id !== "orders")
								? blackAndWhite.appDashBackground
								: undefined
						}
						strokeColor={
							accessibilitySettings?.display?.blackAndWhite || (acceptedOrders !== 1 && app.id !== "orders")
								? blackAndWhite.appDashStroke
								: undefined
						}
						size={50}
						border={activeApp === app.id ? 2 : 0}
					/>
				</div>
				{/* Label */}
				<div className="text-white text-center text-base font-bold leading-normal not-italic pt:0.25 lg:pt-[0.5rem]">
					{app.label}
				</div>
			</button>
		);
	});
	// Returns the app components
	return (
		<div className="flex flex-col lg:flex-row h-[100%] justify-between items-center gap-[.375rem] self-stretch ">
			{appComponents}
		</div>
	);
}

export default AppDashboard;
