import React, { useEffect } from "react";
import { useState } from "react";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import GenericTutorial from "./GenericTutorial";

const IntroTutorial = ({ start, setDisplayTutorial, onTourEnd }) => {
	const [progress, setProgress] = useState(1);
	const [startTour, setStartTour] = useState(start);
	const generateSteps = val => {
		return [
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">
							Hey there, welcome to the Produce Packing Farm. Let's get you up to speed on how we do things around here.
						</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					options: {
						width: 700,
					},
				},
				placement: "center",
				target: "body",
			},
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">
							You will receive orders from customers, figure out how many crates of each produce to pack and then pack
							them into the back of the truck.
						</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					options: {
						width: 700,
					},
				},
				placement: "center",
				target: "body",
			},
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">The orders will be represented by linear equations.</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					options: {
						width: 700,
					},
				},
				placement: "center",
				target: "body",
			},
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">You will need to solve the equations to fill the orders.</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					options: {
						width: 700,
					},
				},
				placement: "center",
				target: "body",
			},
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">Here is all of the apps you will need to fill the orders.</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					overlay: {
						// border: "6px solid #1c7bd4",
						// spotlight: {
						//     border: "2px solid #32CD32",
						// },
						// backgroundColor: 'rgba(0, 0, 0, 0.6)',
					},
					options: {
						width: 700,
					},
				},
				placement: "right",
				target: "#phone-ui__body__app-dash__apps",
			},
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">You will receive the customer's orders through the Orders app</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					options: {
						width: 700,
					},
				},
				placement: "top",
				target: "#orders",
			},
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">You will create your equations through the Equations App</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					options: {
						width: 700,
					},
				},
				placement: "top",
				target: "#equations",
			},
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">You will use the Graph and Notes apps to solve your equations.</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					options: {
						width: 700,
					},
				},
				placement: "top",
				target: "#graph",
			},
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">For now, you will only need to use the Orders and Graph apps.</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					options: {
						width: 700,
					},
				},
				placement: "top",
				target: "#orders",
			},
			{
				content: (
					<div className="p-3">
						<p className="text-4xl">Click on the Orders app to get started!</p>
					</div>
				),
				locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
				styles: {
					options: {
						width: 700,
					},
				},
				placement: "top",
				target: "#orders",
			},
		];
	};

	const [{ run, steps }, setState] = useState({
		run: start,
		stepIndex: 0,
		steps: generateSteps(progress),
	});

	useEffect(() => {
		setState(prevState => ({
			...prevState,
			steps: generateSteps(progress),
		}));
	}, [progress]);

	useEffect(() => {
		if (start) {
			setState(prevState => ({
				...prevState,
				run: true,
				stepIndex: 0,
			}));
		}
	}, [start]);

	const handleJoyrideCallback = data => {
		console.log("handleJoyrideCallback");
		console.log("data", data);
		// eslint-disable-next-line
		svs.setCompleteTutorials("intro");
		if (data.action === "skip") {
			// end of tutorial
			console.log("skipped");
			//set this tutorial as completed
			//eslint-disable-next-line
			const nextTutorial = svs.setCompleteTutorials("intro");

			if (nextTutorial) {
				// set the next tutorial to start
				if (!nextTutorial.complete) {
					setDisplayTutorial(nextTutorial.name);
					console.log("next tutorial", nextTutorial.name);
				}
			}
		}
	};

	return (
		<div id="introTutorialsContainer">
			<GenericTutorial start={startTour} onTourEnd={handleJoyrideCallback} stepsArray={steps} />
		</div>
	);
};

export default IntroTutorial;
