import DynamicInput from "./DynamicInput";
import StaticInput from "./StaticInput";

function SpaceEquationContainer({
	character,
	border,
	borderDark,
	colourPallete,
	studentActivityData,
	orderActivityData,
	equation,
	setStudentActivityAttribute,
}) {
	// Operator input options
	const spaceType = orderActivityData[0].spaceConstraintType;
	/**
	 * Updates the student activity attribute data with the new value
	 * @param {Integer} equation - the id of the equation
	 * @param {String} variable - the variable to update
	 * @param {Event} e - the event object
	 */
	function handleEquationChange(equation, variable, e) {
		const attribute = `eq${equation}${variable}`;
		setStudentActivityAttribute(attribute, e.target.value);
	}
	return (
		<div
			className="w-[100%] flex lg:h-[29%] lg:min-h-[8rem] lg:flex-col items-center lg:items-start justify-center p-[0.625rem_0.9375rem]  gap-[0.9375rem] rounded-[0.4375rem]"
			style={{ border: colourPallete === "blackAndWhite" ? borderDark : border }}
		>
			<div className="flex items-center gap-[0.625rem] w-[25%] min-w-[6rem]">
				<div className="flex items-center  min-w-[5rem] min-h-[1.7rem]">
					<p className="text-[#1A171B] text-[1.375rem] font-bold leading-none">{character}</p>
				</div>
			</div>

			<div className="flex items-center gap-[.25rem] w-[66%] lg:w-[100%] min-w-[18rem] justify-center">
				{/* X */}
				<StaticInput value={"x"} styling={"italic"} />
				{/* + */}
				<StaticInput value={"+"} />
				{/* Y */}
				<StaticInput value={"y"} styling={"italic"} />
				{/* = */}
				<StaticInput value={"="} />
				{/* Input result*/}
				{spaceType === "static" ? (
					<StaticInput value={orderActivityData[0]?.spaceConstraint} />
				) : (
					<DynamicInput
						attribute={"Result"}
						equation={equation}
						handleEquationChange={handleEquationChange}
						studentActivityData={studentActivityData}
					/>
				)}
			</div>
		</div>
	);
}

export default SpaceEquationContainer;
