import DynamicInput from "./DynamicInput";
import StaticInput from "./StaticInput";
/**
 * Component to display the character container
 * @param {Object} props - the props object
 * @param {String} props.character - the character name
 * @param {String} props.border - the border colour
 * @param {String} props.borderDark - the dark border colour
 * @param {String} props.colourPallete - the colour pallete
 * @param {Object} props.studentActivityData - the student activity data
 * @param {Integer} props.equation - the equation number
 * @param {Function} props.setStudentActivityAttribute - the function to set the student activity attribute
 * @returns
 * */
function CharacterContainer({
	character,
	border,
	borderDark,
	colourPallete,
	studentActivityData,
	equation,
	acceptedOrder,
	setStudentActivityAttribute,
}) {
	// Operator input options
	const operatorOptions = ["", "+", "-"];
	/**
	 * Updates the student activity attribute data with the new value
	 * @param {Integer} equation - the id of the equation
	 * @param {String} variable - the variable to update
	 * @param {Event} e - the event object
	 */
	function handleEquationChange(equation, variable, e) {
		const attribute = `eq${equation}${variable}`;
		setStudentActivityAttribute(attribute, e.target.value);
	}

	return (
		<div
			className="w-[100%] flex lg:h-[29%] lg:min-h-[8rem] lg:flex-col items-center lg:items-start justify-center p-[0.625rem_0.9375rem]  gap-[0.9375rem] rounded-[0.4375rem]"
			style={{ border: colourPallete === "blackAndWhite" ? borderDark : border }}
		>
			<div className="flex items-center gap-[0.625rem] w-[25%] min-w-[6rem]">
				<div className="flex items-center  min-w-[5rem] min-h-[1.7rem]">
					<p className="text-[#1A171B] text-[1.375rem] font-bold leading-none">{character}</p>
				</div>
			</div>

			<div className="flex items-center gap-[.25rem] w-[66%] lg:w-[100%] min-w-[18rem] justify-center">
				{/* Input X */}
				{studentActivityData[`equation${acceptedOrder + 1}`].producePriceAType === "static" ? (
					<StaticInput value={studentActivityData[`equation${acceptedOrder + 1}`].producePriceA} />
				) : (
					<DynamicInput
						attribute={"VarX"}
						equation={equation}
						handleEquationChange={handleEquationChange}
						studentActivityData={studentActivityData}
					/>
				)}
				{/* X */}
				<StaticInput value="x" styling={"italic"} />
				{/* Select - Operator */}
				{/* <select
					id="options"
					className="text-black  w-[3.5rem] h-[3.5rem] text-center text-[1.25rem] font-bold leading-none inline-flex items-center gap-[0.75rem] rounded-[0.3125rem] border-[3px] border-[#797979] bg-[#D9D9D9]"
					onChange={e => {
						handleEquationChange(equation, "Operator", e);
					}}
					value={studentActivityData[`eq${equation}Operator`]}
				>
					{operatorOptions.map(option => (
						<option key={option} value={option}>
							{option}
						</option>
					))}
				</select> */}
				<StaticInput value="+" />
				{/* Input Y*/}
				{studentActivityData[`equation${acceptedOrder + 1}`].producePriceBType === "static" ? (
					<StaticInput value={studentActivityData[`equation${acceptedOrder + 1}`].producePriceB} />
				) : (
					<DynamicInput
						attribute={"VarY"}
						equation={equation}
						handleEquationChange={handleEquationChange}
						studentActivityData={studentActivityData}
					/>
				)}
				{/* Y */}
				<StaticInput value="y" styling={"italic"} />
				{/* = */}
				<StaticInput value="=" />
				{/* Input result*/}
				{studentActivityData[`equation${acceptedOrder + 1}`].budgetType === "static" ? (
					<StaticInput value={studentActivityData[`equation${acceptedOrder + 1}`].budget} />
				) : (
					<DynamicInput
						attribute={"Result"}
						equation={equation}
						handleEquationChange={handleEquationChange}
						studentActivityData={studentActivityData}
					/>
				)}
			</div>
		</div>
	);
}

export default CharacterContainer;
