import React, { useState } from "react";
import GenericTutorial from "./GenericTutorial";
import { svs } from "../StudentView/SVS";

const GraphTutorial = ({ setDisplayTutorial }) => {
	const [tutorialStage, setTutorialStage] = useState(1); // set the tutorial stage to 1
	const [startTour, setStartTour] = useState(true); // start tutorial 1

	// // Add event listener to the orders app icon to start the tutorial
	// document.getElementById("graph").addEventListener("click", () => {
	//     console.log('clicked graph app');
	//     setStartTour(true);
	// }, { once: true });

	console.log("GraphTutorial");
	console.log(tutorialStage);

	const getSteps = tutorialStage => {
		const stepsMatrix = [
			[
				// stage 1
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">Let's learn how to use the Graphing App to solve for the x and y values.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								This is the Graphing App. Here you'll be able to plot the x and y values on a graph.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "top",
					target: "#graph",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full">
								{/* <img src={clickOrderTabGif} alt='logo' width={438} height={138} /> */}
							</div>
							<p className="text-4xl">Click on the app icon to start learning about Graphing your equations.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#graph",
				},
			],
			[
				// stage 2
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">Each simultaneous equation is represented by a line on the graph.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#calculator",
					disableBeacon: true,
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">The solid blue line represents the order equation.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#calculator",
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">The dashed orange line represents the space equation.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#calculator",
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">The x value for both equations can be found along the left to right x-axis.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#calculator",
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">The y-value for both equations can found along the bottom to top y-axis.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#calculator",
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">This purple dot represents the number of produce in each truck bed.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#calculator",
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								The purple dot's position on the x-axis is the same as the number of $orderProduceA$ in each truck bed.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#calculator",
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								The purple dot's position on the y-axis is the same as the number of $orderProduceB$ in each truck bed.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#calculator",
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								In this box you will put your answer for the x value. Try it out now and see how the green dot moves
								along the x -axis.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#graphing-container__bottom-container__div__left__input",
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								In this box you will put your answer for the y value. Try it out now and see how the green dot moves
								along the y-axis.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#graphing-container__bottom-container__div__right__input",
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">Let's look at how to pack the truck with produce!</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
				},
			],
		];

		// console.log('stepsMatrix: ', stepsMatrix[tutorialStage-1]);
		return stepsMatrix[tutorialStage - 1];
	};

	const handleTutorialStageEnd = data => {
		console.log("Ending Tutorial Stage");
		console.log("index: ", tutorialStage);

		checkIfTutorialEnded(data);

		setTutorialStage(tutorialStage + 1); // increment the tutorial stage
		console.log("new tutorial stage: ", tutorialStage);

		// custom cleanup on a per stage basis
		switch (
			tutorialStage // index of the current tutorial just completed
		) {
			case 1:
				console.log("Ending tutorial 1");
				console.log("GRAPH Stage 1 TUTORIAL OVER");
				setStartTour(false);

				document.getElementById("graph").addEventListener("click", () => {
					console.log("clicked graph app");
					setTimeout(() => {
						console.log("setStartTour to true");
						setStartTour(true);
					}, 0);
				});
				break;

			case 2:
				console.log("Ending Tutorial 2");
				setStartTour(false);

				// Start the next tutorial (PackingOrdersTutorial)

				console.log("GRAPH TUTORIAL OVER");
				break;

			default:
				break;
		}
	};

	function checkIfTutorialEnded(data) {
		if (data.action === "skip") {
			// end of tutorial
			console.log("skipped");
			//set this tutorial as completed
			//eslint-disable-next-line
			const nextTutorial = svs.setCompleteTutorials("graph");
			console.log(nextTutorial);

			if (nextTutorial) {
				// set the next tutorial to start
				if (!nextTutorial.complete) {
					setDisplayTutorial(nextTutorial.name);
					console.log("next tutorial", nextTutorial.name);
				}
			}
		}
	}

	/**
	 *
	 * @param {Object} data | data object passed to the custom callback, containing info such as index, action, type, etc.
	 * @description custom callback function to handle specific actions during the tutorial, can execute JS for a specific action and index such as playing animations
	 */
	const customCallbackStage2 = data => {
		console.log("customCallbackStage2 CALLED!!!!!");
		console.log(data);

		const produceAList = [];
		const produceBList = [];

		if (data.index === 6) {
			if (data.action === "update") {
				console.log("highlight top crate entity here");

				// eslint-disable-next-line no-undef
				engine.entities.forEach(entry => {
					console.log(entry.name);
					// eslint-disable-next-line no-undef
					if (entry.name.includes(svs.activityData.orderData[0].produceA)) {
						// highlightEntity('bluePallet');
						produceAList.push(entry);
					}
				});

				for (const produceA of produceAList) {
					console.log(produceA);
					highlightEntity(produceA.name);
				}
			}
		}
		if (data.index === 7) {
			if (data.action === "update") {
				removeHighlight();
				console.log("highlight bottom crate entity here");

				// eslint-disable-next-line no-undef
				engine.entities.forEach(entry => {
					console.log(entry.name);
					// eslint-disable-next-line no-undef
					if (entry.name.includes(svs.activityData.orderData[0].produceB)) {
						// highlightEntity('bluePallet');
						produceBList.push(entry);
					}
				});

				console.log(produceBList);
				for (const produceB of produceBList) {
					console.log(produceB);
					highlightEntity(produceB.name);
				}
			}
		}
		if (data.index === 8) {
			if (data.action === "update") {
				console.log("remove highlights here");
				removeHighlight();
			}
		}
	};

	const highlightEntity = entityName => {
		// eslint-disable-next-line no-undef
		engine.tooltipSystem.tutorialMode = true;
		// eslint-disable-next-line no-undef
		outlinePass.selectedObjects.push(engine.getEntityByName(entityName).mesh[0].model);
		// eslint-disable-next-line no-undef
		outlinePass.hiddenEdgeColor.r = 0;
		// eslint-disable-next-line no-undef
		outlinePass.hiddenEdgeColor.g = 1;
		// eslint-disable-next-line no-undef
		outlinePass.hiddenEdgeColor.b = 1;
	};

	const removeHighlight = () => {
		// eslint-disable-next-line no-undef
		engine.tooltipSystem.tutorialMode = false;
		// eslint-disable-next-line no-undef
		outlinePass.selectedObjects = [];
		// eslint-disable-next-line no-undef
		outlinePass.hiddenEdgeColor.r = 1;
		// eslint-disable-next-line no-undef
		outlinePass.hiddenEdgeColor.g = 0;
		// eslint-disable-next-line no-undef
		outlinePass.hiddenEdgeColor.b = 0;
	};

	return (
		<div id="graphTutorialContainer">
			{startTour && tutorialStage === 1 && (
				<GenericTutorial
					start={startTour}
					onTourEnd={handleTutorialStageEnd}
					stepsArray={getSteps(1)}
					//   customCallback={customCallbackStage1}
				/>
			)}
			{startTour && tutorialStage === 2 && (
				<GenericTutorial
					start={startTour}
					onTourEnd={handleTutorialStageEnd}
					stepsArray={getSteps(2)}
					customCallback={customCallbackStage2}
				/>
			)}
		</div>
	);
};

export default GraphTutorial;
