import React, { useState } from 'react';
import GenericTutorial from './GenericTutorial';
import { svs } from '../StudentView/SVS';


const ScoringFeedbackTutorial = (setDisplayTutorial) => {

    const [tutorialStage, setTutorialStage] = useState(1);  // set the tutorial stage to 1
    const [startTour, setStartTour] = useState(true); // start tutorial 1

    console.log("GraphTutorial");
    console.log(tutorialStage);


    const getSteps = (tutorialStage) => {
        const stepsMatrix = [
            [ // stage 1
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            Let's look at how each level is scored once you press the 'Deliver' button.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                },
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            Here is your score for this level.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'bottom',
                    target: document.getElementsByClassName("result-modal-score-current-container")[0],
                },
                {
                    content: (
                        <div className='p-3'>
                            <div className='bg-red-300 w-full'>
                            </div>
                            <p className='text-4xl'>	
                            Your score is based on your work in the Equation and Graph apps. You also get points for packing the truck correctly.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'bottom',
                    target: document.getElementsByClassName("result-modal-score-current-container")[0],
                },
                {
                    content: (
                        <div className='p-3'>
                            <div className='bg-red-300 w-full'>
                            </div>
                            <p className='text-4xl'>	
                            You get points for creating equations, solving equations and packing the truck.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'bottom',
                    target: document.getElementsByClassName("result-modal-score-current-container")[0],
                },
                {
                    content: (
                        <div className='p-3'>
                            <div className='bg-red-300 w-full'>
                            </div>
                            <p className='text-4xl'>	
                            Your best score for the level will be shown here.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'bottom',
                    target: document.getElementsByClassName("result-modal-score-best-container")[0],
                },
                {
                    content: (
                        <div className='p-3'>
                            <div className='bg-red-300 w-full'>
                            </div>
                            <p className='text-4xl'>	
                            You will receive feedback on your attempt here. This will tell you where you can improve going into the next level.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'bottom',
                    target: document.getElementsByClassName("result-modal-feedback-container")[0],
                },
                {
                    content: (
                        <div className='p-3'>
                            <div className='bg-red-300 w-full'>
                            </div>
                            <p className='text-4xl'>	
                            To pass a level, you must score 3.5 stars.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                },
                {
                    content: (
                        <div className='p-3'>
                            <div className='bg-red-300 w-full'>
                            </div>
                            <p className='text-4xl'>	
                            The Redo button allows you to try the current level again.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'bottom',
                    target: document.getElementsByClassName("result-modal-footer-button redo")[0],
                },
                {
                    content: (
                        <div className='p-3'>
                            <div className='bg-red-300 w-full'>
                            </div>
                            <p className='text-4xl'>	
                            The Continue allows you to move onto the next level.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'bottom',
                    target: document.getElementsByClassName("result-modal-footer-button continue")[0],
                },
                {
                    content: (
                        <div className='p-3'>
                            <div className='bg-red-300 w-full'>
                            </div>
                            <p className='text-4xl'>	
                            Click the Continue button to move on!
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'bottom',
                    target:document.getElementsByClassName("result-modal-footer-button continue")[0],
                },
            ]
        ];

        // return tutorialSteps[tutorialStage-1];
        console.log('stepsMatrix: ', stepsMatrix[tutorialStage-1]);
        return stepsMatrix[tutorialStage-1];
    }

    const handleTutorialStageEnd = () => {
		console.log("Ending Tutorial Stage");
		console.log("index: ", tutorialStage);

        setTutorialStage(tutorialStage+1); // increment the tutorial stage
        console.log('new tutorial stage: ', tutorialStage);
    

        // custom cleanup on a per stage basis
		switch (tutorialStage) { // index of the current tutorial just completed
			
			case 1:
				console.log("Ending tutorial 1");
				setStartTour(false);

				// document.getElementById("graph").addEventListener("click", () => {
				// 	console.log('clicked graph app');
                //     setTimeout(() => {
                //         console.log("setStartTour to true");
                //         setStartTour(true);
                //     }, 0);

                //     // console.log(startTour);
                //     // console.log("setStartTour to true");
                //     // console.log(tutorialStage);
                //     // console.log(getSteps(tutorialStage));
				// });
				break;

			case 2:
				console.log("Ending Tutorial 2");
				setStartTour(false);

                // Start the next tutorial (PackingOrdersTutorial)

                console.log("GRAPH TUTORIAL OVER");

				// document.getElementById("single-order__container").addEventListener("click", () => {
				// 	console.log('clicked a single order');
				// 	setTimeout(() => {
				// 		setStartTour(true);
				// 	}, 0);
				// });

				break;
		
			default:
				break;
		}
	}

   
    return (
        <div id="scoringFeedbackTutorialContainer">
            { (startTour && (tutorialStage === 1)) && (
			    <GenericTutorial 
                  start={startTour} 
                  onTourEnd={handleTutorialStageEnd}
                  stepsArray={getSteps(1)}
                //   customCallback={customCallbackStage1}
              />
			)}
            {/* { (startTour && (tutorialStage === 2)) && (
			    <GenericTutorial 
                  start={startTour} 
                  onTourEnd={handleTutorialStageEnd}
                  stepsArray={getSteps(2)}
                  customCallback={customCallbackStage2}
              />
			)} */}
        </div>
    );
};

export default ScoringFeedbackTutorial;