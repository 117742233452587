import React, { useEffect } from 'react';
import { useState } from 'react';
import Joyride, {EVENTS, STATUS} from 'react-joyride';

/**
 * @description | GenericTutorial component - Passs callbacks and steps to dynamically create Joyride components
 * @param {start, onTourEnd, stepsArray, customCallback} | start: boolean, onTourEnd: function, stepsArray: array, customCallback: function
 * @returns | Joyride component
 */
const GenericTutorial = ({ start, onTourEnd, stepsArray, customCallback = undefined}) => { 
    // const [tutorialSteps, setTutorialSteps] = useState(stepsArray);
    const [progress, setProgress] = useState(1);


    const [{run, steps}, setState] = useState({
        run: start,
        stepIndex: 0,
        steps: stepsArray,
    });

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            steps: stepsArray,
        }));
    }, [progress]);

    useEffect(() => {
        if(start) {
            setState((prevState) => ({
                ...prevState,
                run: true,
                stepIndex: 0,
            }));
        }
    }, [start]);

    const handleJoyrideCallback = (data) => {
        console.log("defaultHandleJoyrideCallback");
        console.log(data);

        if(customCallback){
            try {
                console.log("customCallback calling");
                customCallback(data);   
            } catch (error) {
                console.log(error);
            }
        }

        if(data.action === "skip" || data.action === "reset") { // && data.lifecycle === "complete"
            console.log("skip/reset");   
            onTourEnd(data);
        }
    };

    return <Joyride 
    autoStart={true}
    continuous={true}
    callback={handleJoyrideCallback} 
    run={run} 
    steps={steps}
    scrollToFirstStep
    // showProgress={true}
    showSkipButton={true}
    debug
    styles={{
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
        options: {
            // zIndex: 10000,
            // arrowColor: '#1F1F1F',
            // backgroundColor: '#1F1F1F',
            // textColor: '#FFFFFF',
            // overlayColor: 'rgba(0, 0, 0, 0.5)',
            // primaryColor: '#1c7bd4',
            zIndex: 10000,
            arrowColor: '#FFF',
            backgroundColor: '#FFF', //58BCBE
            textColor: '#111111',
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: '#58BCBE',
            // color: '#32CD32',
            // border: "6px solid #32CD32",
        },
        spotlight: {
            border: "2px solid #58BCBE",
        },
    }}
    />
};

export default GenericTutorial;