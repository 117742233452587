import { all } from "axios";
import ProducePackerSubmitButton from "../ProducePacker/ProducePackerSubmitButton";

export default function Scoring({
	accessibilitySettings,
	activityData,
	setActivityDataAttribute,
	setShowResultModal,
	showResultModal,
	setFeedback,
}) {
	// Modify to take in the accepted order
	const acceptedOrder = activityData.studentData.acceptedOrder ?? 0;

	// Get the student inputs for the order accepted data
	const studentEquation1ProducePriceA = activityData.studentData.eq1VarX;
	const studentEquation1ProducePriceB = activityData.studentData.eq1VarY;
	const studentEquation1Budget = activityData.studentData.eq1Result;

	// Get the student inputs for the space constraint
	const studentEquation2ProducePriceA = 1;
	const studentEquation2ProducePriceB = 1;
	const studentEquation2Budget = activityData.studentData.eq2Result;
	// Get the actual produce values for the accepted order
	let {
		producePriceA: orderEquation1ProducePriceA,
		producePriceB: orderEquation1ProducePriceB,
		budget: orderEquation1Budget,
		produceA,
		produceB,
	} = activityData.studentData[`equation${acceptedOrder + 1}`];

	// Get the actual produce values for the space constraint
	const orderEquation2ProducePriceA = 1;
	const orderEquation2ProducePriceB = 1;
	const orderEquation2Budget = activityData.orderData[acceptedOrder]?.spaceConstraint;
	// Need to check the student assignmnet of varX and varY, and switch the actual values of produceA and produceB, if necessary
	// This is because the student can assign varX and varY to either produceA or produceB
	// Swap them if the student has assigned varX to produceB

	if (activityData.studentData.varX === produceB) {
		[orderEquation1ProducePriceA, orderEquation1ProducePriceB] = [
			orderEquation1ProducePriceB,
			orderEquation1ProducePriceA,
		];
		[produceA, produceB] = [produceB, produceA];
	}

	// students data
	const {
		simulationCoordinateX, // simulationCoordinateX - the x answer value that comes from the simulation (number of produceA crate placed in the truck)
		simulationCoordinateY, // simulationCoordinateY - the y answer value that comes from the simulation (number of produceB crate placed in the truck)
		graphCoordinateX, // graphCoordinateX - the x answer value that comes from the input boxes on the graph app
		graphCoordinateY, // graphCoordinateY - the y answer value that comes from the input boxes on the graph app
	} = activityData.studentData;

	// data set by activity
	const { answerX, answerY } = activityData;

	const bestScore = activityData.bestScore;

	const potentialFeedback = {};

	// Solve activity feedback given depends on activity type set by author
	// Allowable values: 'genericSolve', 'algebraSolve', 'graphSolve'
	const solve_activity_type = "genericSolve";

	// Tracking the type and number of mistakes
	const mistakes = {};
	let totalMistakes = 0;

	/* Feedback Dialogue.
	I'm sure this will be saved somewhere differently and possibly in a different format, like in a seperate JSON file.
	Currently no feedback for:
	- Positive feedback when the answer exceeds the treshold score, but there are still mistakes.
	- No distinction between x and y for the 'orders; feedback'
	*/

	// Feedback for a problem with the variable assignment
	const variableAssignmentFeedbackStart = "Pay attention to the variables you assign. ";
	const variableAssignmentFeedbackType = {
		noAssignment: "You need to assign values to both x and y.",
		sameAssignment: "You should assign different values to x and y.",
		xValue: "The value you assigned to x is incorrect.",
		yValue: "The value you assigned to y is incorrect.",
	};

	// Feedback for a problem with the equation
	const equationFeedbackStart =
		"Pay attention to the equations you create. The equations should match the dollar amounts given for the fruits in the order.";
	const equationFeedbackType = {
		mixup: "It looks like you mixed up the numbers in your equations.",
		orders: "You should reread the orders to help you create the equations.",
		budget: "Check the customers' budgets to fill out the final part of your equation.",
	};

	// Feedback for a problem with the solving
	const genericSolveFeedbackStart =
		"You can solve for x and y using graphs or algebra. On the graph, the point at the intersection of the two lines will give you the answer. When solving using algebra, you can use elimination or substitution.";
	const genericSolveFeedbackType = {
		xValue: "Pay closer attention to how you solved for the x value.",
		yValue: "Pay closer attention to how you solved for the y value.",
		bothValues: "Pay closer attention to how you solved for the x and y values.",
	};

	const algebraSolveFeedbackStart = "Pay attention when solving for x and y.";
	const algebraSolveFeedbackType = {
		xValue: "It looks like something went wrong when solving for x.",
		yValue: "It looks like something went wrong when solving for y.",
		bothValues: "It looks like something went wrong when solving for x and y.",
	};

	const graphSolveFeedbackStart =
		"Check the graph when solving for x and y. The point at the intersection of the two lines will give you the answer.";
	const graphSolveFeedbackType = {
		xValue: "Pay closer attention to the x value, which can be read from the horizontal axis.",
		yValue: "Pay closer attention to the y value, which can be read from the vertical axis.",
		bothValues: "Pay closer attention to both the x and y values.",
	};

	// Feedback for a problem with packing
	const packingFeedbackType = {
		produceX: `The amount of ${produceA} you pack for each customer should match the x value you solved for.`,
		produceY: `The amount of ${produceB} you pack for each customer should match the y value you solved for.`,
		bothProduce: `The amount of ${produceA} and ${produceB} you pack for each customer should match the x and y values you solved for.`,
	};

	//This counts the number and type of mistakes the student has made.
	//Currently, if the student has made the same mistake more than three times
	//all it will do make a note of it.
	function countMistakes(mistake, mistakeType) {
		if (mistake in mistakes) {
			if (mistakeType in mistakes[mistake]) {
				if (mistakes[mistake][mistakeType] < 3) {
					mistakes[mistake][mistakeType] += 1;
				} else if (mistakes[mistake][mistakeType] >= 3) {
					// Action needed here
					console.log(`The player has made the same mistake: '${mistake}: ${mistakeType}' 3 or more times.`);
				}
			} else {
				mistakes[mistake][mistakeType] = 1;
			}
		} else {
			mistakes[mistake] = {};
			mistakes[mistake][mistakeType] = 1;
		}

		//Action needed if a certain number of total mistakes are accrued.
		totalMistakes += 1;
	}

	// This constructs the appropriate feedback from the feedback dialogues
	// and inserts it into Chedzeys code
	function giveFeedback(feedback, feedbackType) {
		let feedbackMessage = "";

		switch (feedback) {
			case "variableAssignment":
				feedbackMessage = `${variableAssignmentFeedbackStart} ${variableAssignmentFeedbackType[feedbackType]}`;
				break;
			case "equation":
				feedbackMessage = `${equationFeedbackStart} ${equationFeedbackType[feedbackType]}`;

				break;

			case "genericSolve":
				feedbackMessage = `${genericSolveFeedbackStart} ${genericSolveFeedbackType[feedbackType]}`;

				break;

			case "algebraSolve":
				feedbackMessage = algebraSolveFeedbackStart + algebraSolveFeedbackType[feedbackType];

				break;

			case "graphSolve":
				feedbackMessage = graphSolveFeedbackStart + graphSolveFeedbackType[feedbackType];

				break;

			case "packing":
				feedbackMessage = packingFeedbackType[feedbackType];

				break;

			default:
				feedbackMessage = "Looks like that wasn't quite right. Take another look at your solution and try again.";
		}

		countMistakes(feedback, feedbackType);
		return feedbackMessage;
	}

	const calculateScore = () => {
		// Calculate the score for variable assignment -> 1 mark max
		// Check if all vriables are static, for ue at end when calculating score
		const allVariablesStatic =
			activityData.studentData[`equation${acceptedOrder + 1}`].producePriceAType === "static" &&
			activityData.studentData[`equation${acceptedOrder + 1}`].producePriceBType === "static" &&
			activityData.studentData[`equation${acceptedOrder + 1}`].budgetType === "static";

		const { score: variableAssignmentScore, message: variableAssignmentMessage } = calculateVariableAssignmentScore();
		// Add the feedback message to the potential feedback object if there is a message returned
		console.log("Variable Assignment Score out of 2:", variableAssignmentScore);
		if (variableAssignmentMessage !== "") {
			potentialFeedback.variableAssignment = variableAssignmentMessage;
		}
		// Calculate the score for building the equations -> 2 marks max
		// Constructing the equations to feed to calculateEquationBuildingScore function
		const BuiltEquations = [
			{
				Student_x: Number(studentEquation1ProducePriceA),
				Student_y: Number(studentEquation1ProducePriceB),
				Student_const: Number(studentEquation1Budget),
				Correct_x: Number(orderEquation1ProducePriceA),
				Correct_y: Number(orderEquation1ProducePriceB),
				Correct_const: Number(orderEquation1Budget),
			},
			{
				Student_x: Number(studentEquation2ProducePriceA),
				Student_y: Number(studentEquation2ProducePriceB),
				Student_const: Number(studentEquation2Budget),
				Correct_x: Number(orderEquation2ProducePriceA),
				Correct_y: Number(orderEquation2ProducePriceB),
				Correct_const: Number(orderEquation2Budget),
			},
		];
		// Calculate the score for building the equations (order and space equation) -> 1 mark max
		const [buildScore1, buildScore2] = calculateEquationBuildingScore(BuiltEquations);
		const { equation_build_score: equationBuildScore1, message: buildMessage1 } = buildScore1;
		const { equation_build_score: equationBuildScore2, message: buildMessage2 } = buildScore2;
		console.log("Build Score (0.75)(0.25)", buildScore1, buildScore2);
		// Add the feedback build message 1 to the potential feedback object if there is a message returned
		if (buildMessage1 !== "") {
			potentialFeedback.buildFeedback1 = buildMessage1;
		}
		// Add the feedback build message 2 to the potential feedback object if there is a message returned
		if (buildMessage2 !== "") {
			potentialFeedback.buildFeedback2 = buildMessage2;
		}

		// Calculate the score for solving the equations -> 2 marks max
		// Constructing the equations to feed to calculateEquationSolvingScore function
		const solvedEquations = {
			Eq1_x: BuiltEquations[0].Student_x,
			Eq1_y: BuiltEquations[0].Student_y,
			Eq1_const: BuiltEquations[0].Student_const,

			Eq2_x: BuiltEquations[1].Student_x,
			Eq2_y: BuiltEquations[1].Student_y,
			Eq2_const: BuiltEquations[1].Student_const,

			Student_x: Number(graphCoordinateX), // Student's answer for x (inputted on graph app)
			Student_y: Number(graphCoordinateY), // Student's answer for y (inputted on graph app)
		};
		// Calculate the score for solving the equations -> 2 marks max
		const { score: solveScore, message: solveMessage } = calculateEquationSolvingScore(solvedEquations); // change to use BuiltEquations as input
		console.log("Solve Score:", solveScore);
		// Add the feedback solve message to the potential feedback object if there is a message returned
		if (solveMessage !== "") {
			potentialFeedback.solveFeedback = solveMessage;
		}

		// Calculate the score for packing the truck -> 1 mark max
		const packingData = {
			Student_x: simulationCoordinateX,
			Student_y: simulationCoordinateY,
			Answer_x: answerX,
			Answer_y: answerY,
		};
		// Need to handle if x and y are inverted
		const { score: packingScore, message: packingMessage } = calculatePackingScore(packingData);
		console.log("Packing Score:", packingScore);

		// Add the feedback packing message to the potential feedback object if there is a message returned
		if (packingMessage !== "") {
			potentialFeedback.packingFeedback = packingMessage;
		}
		// Function to return a random feedback message from the potential feedback object
		const randomFeedback = obj => {
			const keys = Object.keys(obj);
			return obj[keys[(keys.length * Math.random()) << 0]];
		};
		console.log("Feedback Score", potentialFeedback);
		// check if the potential feedack object is empty, then everything is okay and return a positive feedback
		if (Object.keys(potentialFeedback).length === 0) {
			setFeedback("Great job! You've completed the activity.");
		} else {
			// Otherwise, return a random feedback message from the potential feedback object
			setFeedback(randomFeedback(potentialFeedback));
		}
		// Calculate the final score to 2 decimal places
		let finalScore;
		if (allVariablesStatic) {
			finalScore = ((variableAssignmentScore + solveScore + packingScore) / 4).toFixed(2);
		} else {
			finalScore = (
				(variableAssignmentScore + equationBuildScore1 + equationBuildScore2 + solveScore + packingScore) /
				5
			).toFixed(2);
		}
		console.log("Final Score:", finalScore);
		// Update the activity data with the final score
		setActivityDataAttribute("currentScore", finalScore);
		// Update the number of retries
		if (activityData.retries !== 0) setActivityDataAttribute("retries", activityData.retries - 1);
		// Update the best score if necessary
		if (finalScore > bestScore) setActivityDataAttribute("bestScore", finalScore);
		// Show the result modal
		setShowResultModal(true);
	};

	/**
	 *
	 * @param {Object} Data | Object containing the student's and correct x and y variable assignment
	 * @returns score for packing the truck
	 */
	function calculateVariableAssignmentScore() {
		// Message to be returned
		let message = "";
		// Score to be returned
		let score = 0;
		// Get the student's x and y variable assignment
		const xStudent = activityData.studentData.varX;
		const yStudent = activityData.studentData.varY;

		// Handle cases where x or y are not assigned
		if (xStudent == null || yStudent == null) {
			message += giveFeedback("variableAssignment", "noAssignment");
			return { score, message };
		}

		// Handle cases where x and y are assigned the same value that isnt null
		if (xStudent === yStudent && xStudent !== "" && yStudent !== "") {
			message += giveFeedback("variableAssignment", "sameAssignment");
			return { score, message };
		}

		// Check for static equations and evaluate x and y against expected values
		const equationKey = `equation${acceptedOrder + 1}`;
		const equation = activityData.studentData[equationKey];
		// If either x or y is static, there is a definitive assignment for the variables
		if (equation.producePriceAType === "static" || equation.producePriceBType === "static") {
			if (xStudent === equation.produceA) {
				score += 0.5;
			} else {
				message += giveFeedback("variableAssignment", "xValue");
			}

			if (yStudent === equation.produceB) {
				score += 0.5;
			} else {
				message += giveFeedback("variableAssignment", "yValue");
			}

			return { score, message };
		}

		// If both x and y are dynamic, there is no definitive assignment for the variables, so once they are different marks will be awarded
		score = xStudent !== yStudent ? 1 : 0;

		return { score, message };
	}

	/**
	 *
	 * @param {Object} BuiltEquations | Array of objects containing the student's built equations and the correct equations
	 * @returns score matrix
	 */
	function calculateEquationBuildingScore(BuiltEquations) {
		let orderScore = 0;
		let spaceScore = 0;
		let orderMessage = "";
		let spaceMessage = "";
		// Get the order data
		const orderData = activityData.studentData[`equation${acceptedOrder + 1}`];
		// Get the number of dynamic variables from the student's equations
		const dynOrderVariables =
			(orderData.producePriceAType?.includes("dynamic") ? 1 : 0) +
			(orderData.producePriceBType?.includes("dynamic") ? 1 : 0) +
			(orderData.budgetType?.includes("dynamic") ? 1 : 0);
		// Get the number of dynamic variables from the space constraint
		const dynSpaceVariables = activityData.orderData[0].spaceConstraintType?.includes("dynamic") ? 1 : 0;
		// If there are no dynamic variables, return full marks
		if (dynOrderVariables === 0 && dynSpaceVariables === 0) {
			return [
				{ equation_build_score: 0.75, message: "" },
				{ equation_build_score: 0.25, message: "" },
			];
		}
		// Calculate correct slope and intercept for the order equation
		const correctSlope = -BuiltEquations[0].Correct_x / BuiltEquations[0].Correct_y;
		const correctIntercept = BuiltEquations[0].Correct_const / BuiltEquations[0].Correct_y;

		// Calculate student slope and intercept for the order equation
		const studentSlope = -BuiltEquations[0].Student_x / BuiltEquations[0].Student_y;
		const studentIntercept = BuiltEquations[0].Student_const / BuiltEquations[0].Student_y;

		// Check if student's equation matches the correct one
		if (studentSlope === correctSlope && studentIntercept === correctIntercept) {
			orderScore = dynOrderVariables / (dynOrderVariables + dynSpaceVariables); // Full marks for a correct solution
		} else {
			// Partial scoring for individual components
			// If variable is dynamic, check if the student has assigned the correct value
			if (orderData.producePriceAType?.includes("dynamic")) {
				if (BuiltEquations[0].Student_x === BuiltEquations[0].Correct_x) {
					orderScore += 1 / (dynOrderVariables + dynSpaceVariables);
				} else {
					orderMessage = "orders"; // Incorrect X component
				}
			}
			// If variable is dynamic, check if the student has assigned the correct value
			if (orderData.producePriceBType?.includes("dynamic")) {
				if (BuiltEquations[0].Student_y === BuiltEquations[0].Correct_y) {
					orderScore += 1 / (dynOrderVariables + dynSpaceVariables);
				} else {
					orderMessage = "orders"; // Incorrect Y component
				}
			}
			// If variable is dynamic, check if the student has assigned the correct value
			if (orderData.budgetType?.includes("dynamic")) {
				if (BuiltEquations[0].Student_const === BuiltEquations[0].Correct_const) {
					orderScore += 1 / (dynOrderVariables + dynSpaceVariables);
				} else {
					orderMessage = "budget"; // Incorrect constant
				}
			}
		}
		// If the student has swapped the x and y components and x and y components are both dynamic, give half marks - 0.25
		if (orderData.producePriceAType?.includes("dynamic") && orderData.producePriceBType?.includes("dynamic")) {
			if (
				BuiltEquations[0].Student_x === BuiltEquations[0].Correct_y &&
				BuiltEquations[0].Student_y === BuiltEquations[0].Correct_x &&
				BuiltEquations[0].Correct_x !== BuiltEquations[0].Correct_y
			) {
				// X and Y components are swapped but correct
				orderScore += 1 / (dynOrderVariables + dynSpaceVariables);
				orderMessage = "mixup"; // Changed feedback here
			}
		}
		if (activityData.orderData[0].spaceConstraintType?.includes("dynamic")) {
			// Evaluate the second equation (space) - focus on the constant value
			if (BuiltEquations[1].Student_const === BuiltEquations[1].Correct_const) {
				spaceScore += 1 / (dynOrderVariables + dynSpaceVariables); // Correct constant
			} else {
				spaceMessage = "budget"; // Incorrect constant
			}
		}

		// Generate feedback messages for each equation
		if (orderMessage) {
			orderMessage = giveFeedback("equation", orderMessage);
		}
		if (spaceMessage) {
			spaceMessage = giveFeedback("equation", spaceMessage);
		}

		return [
			{ equation_build_score: orderScore, message: orderMessage },
			{ equation_build_score: spaceScore, message: spaceMessage },
		];
	}

	/**
	 * @param {Object} equations | Object containing the equations to be solved
	 * @returns {Object} | correct x and y values for the equation
	 */
	function solveSimultaneous2D(equations) {
		// STEP 1:
		const x_coeff = [equations.Eq1_x, equations.Eq2_x];
		const y_coeff = [equations.Eq1_y, equations.Eq2_y];
		const const_coeff = [equations.Eq1_const, equations.Eq2_const];

		const eliminator = [];
		eliminator[0] = [];
		eliminator[1] = [];
		let x_variable;
		let y_variable;
		// STEP 2:
		eliminator[0][0] = y_coeff[1] * x_coeff[0];
		eliminator[0][1] = y_coeff[1] * const_coeff[0];
		// STEP 3:
		eliminator[1][0] = y_coeff[0] * x_coeff[1];
		eliminator[1][1] = y_coeff[0] * const_coeff[1];

		try {
			// STEPS 4, 5:
			x_variable = (eliminator[0][1] - eliminator[1][1]) / (eliminator[0][0] - eliminator[1][0]);
			// STEP 6:
			y_variable = (const_coeff[0] - x_coeff[0] * x_variable) / y_coeff[0];

			return { x: x_variable, y: y_variable };
		} catch (ex) {
			console.error("Error: ", ex);
		}
	}

	function calculateEquationSolvingScore(equations) {
		let message = "";

		const equationsResult = solveSimultaneous2D(equations); // correct x and y values for the equation

		let score = 0;
		// I've wrapped the existing code in this if statement to
		// give feedback when both values are wrong.
		if (equationsResult.x !== equations.Student_x && equationsResult.y !== equations.Student_y) {
			message += giveFeedback(solve_activity_type, "bothValues");
		} else {
			if (equationsResult.x === equations.Student_x) score++; // increment score if x is correct
			else message += giveFeedback(solve_activity_type, "xValue");
			if (equationsResult.y === equations.Student_y) score++; // increment score if y is correct
			else message += giveFeedback(solve_activity_type, "yValue");
		}

		return { score: score, message: message };
	}

	function calculatePackingScore(Data) {
		let message = "";
		const x_student = Data.Student_x;
		const x_answer = Data.Answer_x;
		const x_score = 1 / (1 + Math.abs(x_student - x_answer));

		const y_student = Data.Student_y;
		const y_answer = Data.Answer_y;
		const y_score = 1 / (1 + Math.abs(y_student - y_answer));

		// Changed here
		// I've wrapped the existing code in this if statement to
		// give feedback when both values are wrong.
		if (y_score < 1 && x_score < 1) {
			message = giveFeedback("packing", "bothProduce");
		} else {
			if (x_score < 1) message += giveFeedback("packing", "produceX");
			if (y_score < 1) message += giveFeedback("packing", "produceY");
		}

		const score_packing = 0.5 * x_score + 0.5 * y_score;

		const scorePackingObject = { score: score_packing, message: message };

		return scorePackingObject;
	}

	return (
		<ProducePackerSubmitButton
			accessibilitySettings={accessibilitySettings}
			showResultModal={showResultModal}
			onClickCallback={calculateScore}
		/>
	);
}
