import React, { useState } from "react";
import GenericTutorial from "./GenericTutorial";
import { svs } from "../StudentView/SVS";

const EquationsTutorial = setDisplayTutorial => {
	const [tutorialStage, setTutorialStage] = useState(1); // set the tutorial stage to 1
	const [startTour, setStartTour] = useState(true); // start tutorial 1

	console.log("GraphTutorial");
	console.log(tutorialStage);

	const getSteps = tutorialStage => {
		const stepsMatrix = [
			[
				// stage 1
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								The orders are getting more complicated. Let's look at a new app we can use to work them out.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								This is the Equation App. Here you'll be able to fill in your answers to construct your equations
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								Click on the app icon to start learning about how the Equation App can help you create your equations.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
			],
			[
				// stage 2
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">Here you can choose which produce is represented by the x and y.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body", //
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								Let's set the variable x as $ProduceA$ . Click the dropdown beside the x to set it to $ProduceA$.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
			],
			[
				// stage 3
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">Now, set the variable y.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">Setting your variables correctly will improve your score.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								Both equations are displayed here using the variables you set. There may be some missing information
								from each equation in some levels.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								Here you can make changes to the first equation. This order is from $customerA$.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								Here you can make changes to the second equation. This order is from $customerB$.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								This is a coefficient. A coefficient is the number that is multiplied by the variable.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								You may need to fill in the coefficient to complete some equations. You should reread the order again
								when working out the coefficients.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								This is a constant. A constant is a value that cannot change. In this example the constant is the total
								for the order.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								You may need to fill in a constant value to complete some equations. You should reread the order again
								when working out any constant values.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								The Equation App can be used with the Graphing App to help you solve the equations.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
			],
			[
				// stage 4
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">Remember: The lines on the graph represent the two simultaneous equations.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								Remember: The values you input in the x and y spaces will contribute to your score.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								Click on the Equation App again to go back to it. Use both apps to find your answers for the level.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full"></div>
							<p className="text-4xl">
								The values you input in any empty coefficient and constant spaces in the Equation will contribute to
								your score.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#equations",
				},
			],
		];

		// return tutorialSteps[tutorialStage-1];
		console.log("stepsMatrix: ", stepsMatrix[tutorialStage - 1]);
		return stepsMatrix[tutorialStage - 1];
	};

	const handleTutorialStageEnd = data => {
		console.log("Ending Tutorial Stage");
		console.log("index: ", tutorialStage);

		checkIfTutorialEnded(data);

		setTutorialStage(tutorialStage + 1); // increment the tutorial stage
		console.log("new tutorial stage: ", tutorialStage);

		// custom cleanup on a per stage basis
		switch (
			tutorialStage // index of the current tutorial just completed
		) {
			case 1:
				console.log("Ending tutorial 1");
				setStartTour(false);

				// document.getElementById("graph").addEventListener("click", () => {
				// 	console.log('clicked graph app');
				//     setTimeout(() => {
				//         console.log("setStartTour to true");
				//         setStartTour(true);
				//     }, 0);

				//     // console.log(startTour);
				//     // console.log("setStartTour to true");
				//     // console.log(tutorialStage);
				//     // console.log(getSteps(tutorialStage));
				// });
				break;

			case 2:
				console.log("Ending Tutorial 2");
				setStartTour(false);

				// Start the next tutorial (PackingOrdersTutorial)

				console.log("GRAPH TUTORIAL OVER");

				// document.getElementById("single-order__container").addEventListener("click", () => {
				// 	console.log('clicked a single order');
				// 	setTimeout(() => {
				// 		setStartTour(true);
				// 	}, 0);
				// });

				break;

			default:
				break;
		}
	};

	function checkIfTutorialEnded(data) {
		console.log("data received: ", data);
		if (data.action === "skip") {
			// end of tutorial
			console.log("skipped");

			//eslint-disable-next-line
			const nextTutorial = svs.setCompleteTutorials("orders");
			if (nextTutorial) {
				// set the next tutorial to start
				if (!nextTutorial.complete) {
					setDisplayTutorial(nextTutorial.name);
					console.log("next tutorial", nextTutorial.name);
				}
			}
		}
	}

	return (
		<div id="scoringFeedbackTutorialContainer">
			{startTour && tutorialStage === 1 && (
				<GenericTutorial
					start={startTour}
					onTourEnd={handleTutorialStageEnd}
					stepsArray={getSteps(1)}
					//   customCallback={customCallbackStage1}
				/>
			)}
			{/* { (startTour && (tutorialStage === 2)) && (
			    <GenericTutorial 
                  start={startTour} 
                  onTourEnd={handleTutorialStageEnd}
                  stepsArray={getSteps(2)}
                  customCallback={customCallbackStage2}
              />
			)} */}
		</div>
	);
};

export default EquationsTutorial;
