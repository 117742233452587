import React from "react";

const DynamicInput = ({ equation, attribute, handleEquationChange, studentActivityData }) => {
	return (
		<div>
			<input
				id={`graphing-container__bottom-container__div__left__input-${equation}`}
				className="flex w-[3.5rem] h-[3.5rem] p-[0.75rem] px-[0.225rem] lg:py-[0.75rem] flex-col justify-center items-center gap-[0.625rem] lg:gap-[2.5rem] flex-shrink-0 rounded-[0.4375rem] border-[3px] border-[#797979] bg-[#D9D9D9] text-black text-center text-[1.5rem] font-bold leading-normal"
				onChange={e => handleEquationChange(equation, attribute, e)}
				value={studentActivityData[`eq${equation}${attribute}`]}
			/>
		</div>
	);
};

export default DynamicInput;
