import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";

// Define the cache expiration time in milliseconds (e.g., 3 minutes)
const CACHE_EXPIRATION_TIME = 14 * 60 * 1000; // 3 minutes

// This creates an axios instance with Okta token and caching functionality
const CreateAxiosInstance = () => {
	// Get the oktaAuth instance from the okta context
	const { oktaAuth } = useOktaAuth();

	// Create an axios instance
	const axiosInstance = axios.create({
		baseURL: process.env?.REACT_APP_API_URL || window.APP_ENV?.REACT_APP_API_URL, // Use the environment variable for the base URL
	});

	// Request Interceptor to add Okta token to headers and check cache
	axiosInstance.interceptors.request.use(
		async config => {
			// Check if the response for this request is cached in sessionStorage
			const cacheKey = config.url;
			const cachedData = sessionStorage.getItem(cacheKey);

			if (cachedData) {
				// Parse cached data and check if it's expired
				const { timestamp, data } = JSON.parse(cachedData);
				const isExpired = Date.now() - timestamp > CACHE_EXPIRATION_TIME;

				if (!isExpired) {
					// If cached and not expired, return the cached response
					console.log("Returning cached data for:", config.url);
					config.adapter = () => Promise.resolve({ data });
				} else {
					// If expired, remove from sessionStorage
					sessionStorage.removeItem(cacheKey);
					console.log("Cache expired, fetching new data for:", config.url);
				}
			}

			// Retrieve the Okta access token and add it to the headers
			const token = await oktaAuth.getAccessToken();
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}

			return config;
		},
		error => {
			return Promise.reject(error);
		},
	);

	// Response Interceptor to cache the response with a timestamp
	axiosInstance.interceptors.response.use(
		response => {
			// Ensure response.config and response.config.url are available
			const cacheKey = response.config?.url;
			if (cacheKey) {
				// Store the data along with a timestamp for expiration checking
				const cacheData = {
					data: response.data,
					timestamp: Date.now(), // Add timestamp when data was cached
				};
				sessionStorage.setItem(cacheKey, JSON.stringify(cacheData)); // Cache the response
				console.log("Caching response for:", cacheKey);
			} else {
				console.warn("No URL found in response config, skipping caching.");
			}
			return response;
		},
		error => {
			return Promise.reject(error);
		},
	);

	return axiosInstance;
};

export default CreateAxiosInstance;
