import React, { useState } from "react";
import GenericTutorial from "./GenericTutorial";
import { svs } from "../StudentView/SVS";

const OrdersTutorial = ({ setDisplayTutorial }) => {
	const [tutorialStage, setTutorialStage] = useState(1); // set the tutorial stage to 1
	const [startTour, setStartTour] = useState(true); // start tutorial 1

	// // Add event listener to the orders app icon to start the tutorial
	// document.getElementById("orders").addEventListener("click", () => {
	//     console.log('clicked orders app');
	//     setStartTour(true);
	// }, { once: true });

	console.log("OrdersTutorial");
	console.log(tutorialStage);

	const getSteps = tutorialStage => {
		const stepsMatrix = [
			[
				// stage 1
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">Let's learn how to use the Orders App to accept orders from your customers.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								This is the Orders App, here you'll get orders from your customers as messages.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "top",
					target: "#orders",
				},
				{
					content: (
						<div className="p-3">
							<div className="bg-red-300 w-full">
								{/* <img src={contClickAnimGif} alt='logo' width={438} height={138} /> */}
							</div>
							<p className="text-4xl">Click on the app icon to start learning about Orders.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#orders",
				},
			],
			[
				// stage 2
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">Each customer's order is contained in a message from that customer.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#single-order__container",
					disableBeacon: true,
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">You can see each customers name on their order.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#phone-ui__order__title",
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">This is their profile picture, so you can quickly recognize them.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "bottom",
					target: "#friendship-container__relative",
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								Unread messages have a red dot, this disappears once you read a customer's message.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">Click on the first message to read the customer's order.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "top",
					target: "#single-order__container",
				},
			],
			[
				// stage 3
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">Each message has details about a customer's order.</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#order-message-container__top__contents",
					disableBeacon: true,
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								You have a friendship score with every customer, as you complete orders for a customer your friendship
								with them will improve.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "right",
					target: "#friendship-container__absolute",
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								The most important details of each order are highlighted a different color. You'll use these details to
								create a linear equation for each order in the Equations App.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "bottom",
					target: "#order-message-container__top__contents",
				},

				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								You need to accept one order to fulfil. To accept an order click on the Accept button.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "top",
					target: "#accept-order-button",
				},
			],
			// [
			// 	// stage 4
			// 	{
			// 		content: (
			// 			<div className="p-3">
			// 				<p className="text-4xl">Now read and accept the second order!</p>
			// 			</div>
			// 		),
			// 		locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
			// 		styles: {
			// 			options: {
			// 				width: 700,
			// 			},
			// 		},
			// 		placement: "center",
			// 		target: "body",
			// 		disableBeacon: true,
			// 	},
			// ],
			[
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								The second linear equation for this level is based on the amount of space in the back of the truck. In
								this level, there are $spaceConstraint$ spaces on the truck.
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
					disableBeacon: true,
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								You will be counting this yourself in later levels when you use the Equations app!{" "}
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
					disableBeacon: true,
				},
				{
					content: (
						<div className="p-3">
							<p className="text-4xl">
								Now that you have accepted the order and we have our Space equation click on the Graph App icon!{" "}
							</p>
						</div>
					),
					locale: { skip: <strong aria-label="skip">Skip tutorial</strong> },
					styles: {
						options: {
							width: 700,
						},
					},
					placement: "center",
					target: "body",
					disableBeacon: true,
				},
			],
		];

		return stepsMatrix[tutorialStage - 1];
	};

	/**
	 * @description callback function to handle the end of a tutorial stage, increment the tutorial stage and set the startTour state to true to start the next tutorial
	 * Use this as a cleanup function to handle the end of a tutorial stage and setup the next tutorial stage
	 * @returns void
	 */
	const handleTutorialStageEnd = data => {
		checkIfTutorialEnded(data);

		console.log("Ending Tutorial Stage");
		console.log("index: ", tutorialStage);

		setTutorialStage(tutorialStage + 1); // increment the tutorial stage
		console.log("new tutorial stage: ", tutorialStage);

		// custom cleanup on a per stage basis
		switch (
			tutorialStage // index of the current tutorial just completed
		) {
			case 1:
				console.log("Ending tutorial 1");
				setStartTour(false);

				document.getElementById("orders").addEventListener("click", () => {
					console.log("clicked orders app");
					setStartTour(true);
				});
				break;

			case 2:
				console.log("Ending Tutorial 2");
				setStartTour(false);

				console.log("adding event listener to single order");
				document.getElementById("single-order__container").addEventListener("click", () => {
					console.log("clicked a single order");
					setTimeout(() => {
						setStartTour(true);
					}, 0);
				});

				break;

			case 3:
				console.log("Ending Tutorial 3");
				setStartTour(false);

				document.getElementById("accept-order-button").addEventListener("click", () => {
					console.log("clicked the accept order");
					setTimeout(() => {
						setStartTour(true);
					}, 0);
				});

				break;

			case 4:
				console.log("Ending Tutorial 4");
				// setStartTour(false);

				console.log(setDisplayTutorial);

				// start the graph tutorial next
				setDisplayTutorial("graph");
				console.log("displaying graph tutorial");

				// do nothing
				break;

			default:
				break;
		}
	};

	const addClickHereGif = parentId => {
		setTimeout(() => {
			console.log("addClickHereGif");
			console.log(parentId);

			if (!document.getElementById("contClickAnimGif")) {
				console.log("here first");
				const img = document.createElement("img");
				img.src = "http://localhost:3000/playCont.gif";
				img.id = "contClickAnimGif";
				document.getElementById("student-view__container").appendChild(img);
			}

			const img = document.getElementById("contClickAnimGif");

			const rect = document.getElementById(parentId).getBoundingClientRect();
			console.log(rect.top, rect.right, rect.bottom, rect.left);

			img.style.position = "fixed";
			img.style.pointerEvents = "none";
			img.style.display = "";
			img.style.transform = "translate(-50%, -50%)";
			img.style.width = "270px";
			img.style.height = "270px";

			img.style.left = `${(rect.left + rect.right) / 2}px`;
			img.style.top = `${(rect.top + rect.bottom) / 2}px`;
		}, 0);
	};

	/**
	 *
	 * @param {Object} data | data object passed to the custom callback, containing info such as index, action, type, etc.
	 * @description custom callback function to handle specific actions during the tutorial, can execute JS for a specific action and index such as playing animations
	 */
	const customCallbackStage1 = data => {
		console.log("customCallbackStage1");
		console.log(data);

		if (data.index === 2) {
			if (data.action === "update") {
				console.log("addClickHereGif");
				addClickHereGif("orders");
			}
			//add event listener to orders message to start next tutorial
			document.getElementById("orders").addEventListener("click", () => {
				console.log("clicked orders");
				document.getElementById("contClickAnimGif").style.display = "none"; //hide the click animation
			});
		}
	};

	const customCallbackStage2 = data => {
		console.log("customCallbackStage2");

		if (data.index === 4) {
			if (data.action === "update") {
				console.log("addClickHereGif");
				addClickHereGif("single-order__container");
			}

			document.getElementById("single-order__container").addEventListener("click", () => {
				document.getElementById("contClickAnimGif").style.display = "none"; //hide the click animation
			});
		}
	};

	const customCallbackStage3 = data => {
		console.log("customCallbackStage3");

		if (data.index === 3) {
			if (data.action === "update") {
				console.log("addClickHereGif");
				addClickHereGif("accept-order-button");
			}

			//accept-order-button
			document.getElementById("accept-order-button").addEventListener("click", () => {
				document.getElementById("contClickAnimGif").style.display = "none"; //hide the click animation
			});
		}
	};

	let customCallbackStage4Called = false;
	const customCallbackStage4 = data => {
		console.log("customCallbackStage4");

		if (data.index === 0) {
			if (data.action === "update") {
				if (customCallbackStage4Called) return;
				customCallbackStage4Called = true;
				console.log("addClickHereGif in back button");
				addClickHereGif("back-to-orders-button"); // add anim to back button

				document.getElementById("back-to-orders-button").addEventListener(
					"click",
					() => {
						// add event listener to back button
						document.getElementById("contClickAnimGif").style.display = "none"; //hide the click animation

						setTimeout(() => {
							console.log("setTimeout");

							// add click anim to the second order
							addClickHereGif("single-order__container"); // no independent id for the second order, so using phone screen for now //FIX!!!

							// add event listener to second order
							document.getElementById("single-order__container").addEventListener(
								"click",
								() => {
									document.getElementById("contClickAnimGif").style.display = "none"; //hide the click animation

									setTimeout(() => {
										console.log("setTimeout");
										// add click anim to accept button
										addClickHereGif("accept-order-button");

										// add event listener to accept button
										document.getElementById("accept-order-button").addEventListener(
											"click",
											() => {
												console.log(3);
												document.getElementById("contClickAnimGif").style.display = "none"; //hide the click animation
											},
											{ once: true },
										);
									}, 0);
								},
								{ once: true },
							);
						}, 0);
					},
					{ once: true },
				);
			}
		}
	};

	function checkIfTutorialEnded(data) {
		if (data.action === "skip") {
			// end of tutorial
			console.log("skipped");
			//eslint-disable-next-line
			const nextTutorial = svs.setCompleteTutorials("orders");
			if (nextTutorial) {
				// set the next tutorial to start
				if (!nextTutorial.complete) {
					setDisplayTutorial(nextTutorial.name);
					console.log("next tutorial", nextTutorial.name);
				}
			}
		}
	}

	return (
		<div id="ordersTutorialContainer">
			{startTour && tutorialStage === 1 && (
				<GenericTutorial
					start={startTour}
					onTourEnd={handleTutorialStageEnd}
					stepsArray={getSteps(1)}
					customCallback={customCallbackStage1}
				/>
			)}
			{startTour && tutorialStage === 2 && (
				<GenericTutorial
					start={startTour}
					onTourEnd={handleTutorialStageEnd}
					stepsArray={getSteps(2)}
					customCallback={customCallbackStage2}
				/>
			)}
			{startTour && tutorialStage === 3 && (
				<GenericTutorial
					start={startTour}
					onTourEnd={handleTutorialStageEnd}
					stepsArray={getSteps(3)}
					customCallback={customCallbackStage3}
				/>
			)}
			{startTour && tutorialStage === 4 && (
				<GenericTutorial
					start={startTour}
					onTourEnd={handleTutorialStageEnd}
					stepsArray={getSteps(4)}
					customCallback={customCallbackStage4}
				/>
			)}
			{startTour &&
				tutorialStage === 5 &&
				svs.activityData.studentData.equation1.orderAccepted &&
				svs.activityData.studentData.equation2.orderAccepted && (
					<GenericTutorial start={startTour} onTourEnd={handleTutorialStageEnd} stepsArray={getSteps(5)} />
				)}
		</div>
	);
};

export default OrdersTutorial;
