import React, { useEffect, useRef, useState } from "react";

/**
 * Functional component for the Notes Tab
 * @param {Object} props - the props object
 * @param {Object} props.studentActivityData - the student activity data
 * @param {String} props.colourPallete - the colour pallete
 * @returns
 * */
export default function NotesTab({ studentActivityData, colourPallete, setStudentActivityAttribute }) {
	const { eq1VarX, eq1VarY, eq1Operator, eq1Result, eq2Result } = studentActivityData;

	// Function to handle changes in the notes data
	const handleChange = e => {
		setStudentActivityAttribute("notes", e.target.value);
	};

	/**
	 * Formats an equation string to apply different styling to variables x and y.
	 * @param {string} [varX] - Coefficient of x (optional).
	 * @param {string} [operator] - Operator between variables x and y (optional).
	 * @param {string} [varY] - Coefficient of y (optional).
	 * @param {string} [result] - Result of the equation (optional).
	 * @returns {string} Formatted equation string with styled variables.
	 */
	function formatEquation(varX = "", operator = "", varY = "", result = "") {
		// Construct the equation string
		let equation = `${varX ?? ""}x${operator ?? "+"}${varY ?? ""}y=${result ?? ""}`;

		// Replace variables with styled HTML spans
		equation = equation.replace(
			/([xy])/g,
			'<span class="text-[#232121] text-[1.375rem] lg:text-[1.5rem] italic font-bold leading-normal">$1</span>',
		);

		return equation;
	}

	// Usage
	const equation1 = formatEquation(eq1VarX, eq1Operator, eq1VarY, eq1Result);
	const equation2 = formatEquation("", "+", "", eq2Result); // Example for `x+y=...`

	return (
		<div className="h-[81%] flex p-[0.75rem_0.625rem] lg:p-[0.9375rem_0.625rem] flex-col items-center gap-[0.625rem] flex-shrink-0">
			{/* Equation 1 */}
			<div
				style={{ border: colourPallete === "blackAndWhite" ? "4px dashed #232121" : "4px dashed #F9A366" }}
				className="min-h-[2.4375rem] lg:min-h-[2.8rem] lg:p-[.25rem_0rem] h-[18%] flex-shrink-0 self-stretch rounded-[0.3125rem] border-[4px] flex items-center justify-center "
			>
				<p
					dangerouslySetInnerHTML={{ __html: equation1 }}
					className="text-[#232121] text-center text-[1.375rem] lg:text-[1.5rem] font-bold leading-normal"
				/>
			</div>
			{/* Equation 2 */}
			<div
				style={{ border: colourPallete === "blackAndWhite" ? "4px solid #D9D9D9" : "4px solid #00A7E4" }}
				className="min-h-[2.4375rem] lg:p-[.25rem_0rem] lg:min-h-[2.8rem] lg:gap-[.625rem] h-[18%] flex-shrink-0 self-stretch rounded-[0.3125rem] border-[4px] border-[#00A7E4] flex items-center justify-center"
			>
				<p
					dangerouslySetInnerHTML={{ __html: equation2 }}
					className="text-[#232121] text-center text-[1.375rem] lg:text-[1.5rem] font-bold leading-normal"
				/>
			</div>
			{/* Note text input  */}
			<div className="w-[100%] h-[55%]">
				<textarea
					className="rounded-[0.125rem] border-[2px] border-[rgba(147,147,147,0.50)] bg-[rgba(147,147,147,0.10)] w-full h-full p-2 align-top resize-none overflow-hidden leading-relaxed"
					value={studentActivityData.notes}
					onChange={handleChange}
					placeholder="Write your notes here..."
				/>
			</div>
		</div>
	);
}
