import React from "react";

const StaticInput = ({ value, styling }) => {
	return (
		<p
			className={`${styling} text-black text-center text-[1.375rem] font-bold leading-none flex w-[1.875rem] h-[1.875rem] p-[0.75rem] px-[0.625rem] flex-col justify-center items-center gap-[0.625rem] flex-shrink-0`}
		>
			{value}
		</p>
	);
};

export default StaticInput;
