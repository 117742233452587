import React, { useState } from 'react';
import GenericTutorial from './GenericTutorial';
import { svs } from '../StudentView/SVS';


const PackingTutorial = ({setDisplayTutorial}) => {
    const [tutorialStage, setTutorialStage] = useState(1);  // set the tutorial stage to 1
    const [startTour, setStartTour] = useState(true); // start tutorial 1

    // // Add event listener to the orders app icon to start the tutorial
    // document.getElementById("graph").addEventListener("click", () => {
    //     console.log('clicked graph app');
    //     setStartTour(true);
    // }, { once: true });

    console.log("PackingTutorial");
    console.log(tutorialStage);

    const getSteps = (tutorialStage) => {
        const stepsMatrix = [
            [ // stage 1
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            Let's learn how to pack the truck with the produce ordered by your customers.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                },
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            This is the Produce Section. Here is where you will find the produce your customers want.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: '#simulationWindow',
                },
                {
                    content: (
                        <div className='p-3'>
                            <div className='bg-red-300 w-full'>
                                {/* <img src={contClickAnimGif} alt='logo' width={438} height={138} /> */}
                            </div>
                            <p className='text-4xl'>	
                            Click on a crate of produce to pack it onto the truck.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: '#simulationWindow',
                }
            ],
            [ // stage 2
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            When you place a crate on the truck, you can see the purple dot move along the xy-axis. Place another crate on the truck and watch the dot move.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                }
            ],
            [ // stage 3
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            You can click on the crate in the truck to remove it.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                },
            ], 
            [ // stage 4
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            You should pack the truck based on your answers in the Graph app.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                },
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            Let's look at your x answer. The amount of $ProduceA$ that you pack should match your x answer.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                },
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            Pack the truck with $ProduceA$
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                },
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            Now, pack the truck with $ProduceB$.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                },
                {
                    content: (
                        <div className='p-3'>
                            <p className='text-4xl'>	
                            When you have finished packing the truck, click the Deliver button.
                            </p>
                        </div>
                    ),
                    locale: { skip: <strong aria-label='skip'>Skip tutorial</strong> },
                    styles: {
                        options: {
                            width: 700,
                        },
                    },
                    placement: 'center',
                    target: 'body',
                }
            ]
        ];

        // return tutorialSteps[tutorialStage-1];
        return stepsMatrix[tutorialStage-1];
    }

    /**
     * @description callback function to handle the end of a tutorial stage, increment the tutorial stage and set the startTour state to true to start the next tutorial
     * Use this as a cleanup function to handle the end of a tutorial stage and setup the next tutorial stage
     * @returns void
     */
    const handleTutorialStageEnd = () => {
		console.log("Ending Tutorial Stage");
		console.log("index: ", tutorialStage);

        setTutorialStage(tutorialStage+1); // increment the tutorial stage
        console.log('new tutorial stage: ', tutorialStage);
    

        // custom cleanup on a per stage basis
		switch (tutorialStage) { // index of the current tutorial just completed
			
			case 1:
				console.log("Ending tutorial 1");
				setStartTour(false);

				// document.getElementById("orders").addEventListener("click", () => {
				// 	console.log('clicked orders app');
                //     setStartTour(true);
				// });
				break;

			case 2:
				console.log("Ending Tutorial 2");
				setStartTour(false);

				// document.getElementById("single-order__container").addEventListener("click", () => {
				// 	console.log('clicked a single order');
				// 	setTimeout(() => {
				// 		setStartTour(true);
				// 	}, 0);
				// });

				break;

			case 3:
				console.log("Ending Tutorial 3");
				setStartTour(false);

				// document.getElementById("order-message__button--accept").addEventListener("click", () => {
				// 	console.log('clicked the accept order');
				// 	setTimeout(() => {
				// 		setStartTour(true);
				// 	}, 0);
				// });

				break;

			case 4:
				console.log("Ending Tutorial 4");
                // setStartTour(false);

                console.log(setDisplayTutorial)

                // start the graph tutorial next
                // setDisplayTutorial("graph");
                // console.log("displaying graph tutorial");

                document.getElementById('calculate_score_button').addEventListener('mousedown', (event) => {

                    console.log('clicked calculate score button');
                    console.log("Scoring|Feedback Tutorial is now going to start")

                    console.log('mofungo cuatro 4')
                    console.log(event);
                    // setStartTour(true);
                    setDisplayTutorial("scoringFeedback");
                    setStartTour(true);
                });

                // do nothing
				break;
    
            case 5:
            console.log("Ending Tutorial 5");
            // setStartTour(false);

            console.log(setDisplayTutorial)

            // start the graph tutorial next
            setDisplayTutorial("graph");
            console.log("displaying graph tutorial");

            // do nothing
            break;



			default:
				break;
		}
	}

    function checkIfTutorialEnded(data){
        if (data.action === "skip") { // end of tutorial
            console.log("skipped");
            //set this tutorial as completed
            //eslint-disable-next-line
            const nextTutorial = svs.setCompleteTutorials("packing");

            // get next tutorial
            //eslint-disable-next-line
            // const nextTutorial = svs.getNextIncompleteTutorial();

            if(nextTutorial) {
                // set the next tutorial to start
                if(!nextTutorial.complete){
                    setDisplayTutorial(nextTutorial.name);
                    console.log("next tutorial", nextTutorial.name);
                }
            }
        }
    }

    const addClickHereGif = (parentId, coordsXY = {}) => {
        setTimeout(() => {
            console.log("addClickHereGif");
            console.log(parentId);

            if(!document.getElementById("contClickAnimGif")) {
                console.log("here first");
                const img = document.createElement('img');
                img.src = "http://localhost:3000/playCont.gif";
                img.id = "contClickAnimGif";
                document.getElementById("student-view__container").appendChild(img);
            } 


            if(parentId === false){
                // use coordsXY to place the gif
                const img = document.getElementById("contClickAnimGif");
                img.style.position = "fixed";
                img.style.pointerEvents = "none";
                img.style.display = "";
                img.style.transform = "translate(-50%, -50%)";
                img.style.width="270px"
                img.style.height="270px"

                console.log(coordsXY);

                img.style.left = `${coordsXY.x}px`;
                img.style.top = `${coordsXY.y}px`;
            } else {

                const img = document.getElementById("contClickAnimGif");

                const rect = document.getElementById(parentId).getBoundingClientRect();
                console.log(rect.top, rect.right, rect.bottom, rect.left);

                img.style.position = "fixed";
                img.style.pointerEvents = "none";
                img.style.display = "";
                img.style.transform = "translate(-50%, -50%)";
                img.style.width="270px"
                img.style.height="270px"

                img.style.left = `${(rect.left + rect.right) / 2}px`;
                img.style.top = `${(rect.top + rect.bottom) / 2}px`;
            }
        }, 0);
    }

    const getEntityScreenCoords = (entityId) => {
        //eslint-disable-next-line no-undef
        const vector = new THREE.Vector3();
        // eslint-disable-next-line no-undef
        const renderer = engine.getRenderer()
        const widthHalf = 0.5*renderer.getContext().canvas.width;
        const heightHalf = 0.5*renderer.getContext().canvas.height;
        // eslint-disable-next-line no-undef
        const obj = engine.entities.get(entityId).mesh[0].model;
        obj.updateMatrixWorld();
        vector.setFromMatrixPosition(obj.matrixWorld);
        // eslint-disable-next-line no-undef
        vector.project(engine.getCamera());
    
        vector.x = ( vector.x * widthHalf ) + widthHalf;
        vector.y = - ( vector.y * heightHalf ) + heightHalf;
        
        return { 
            x: vector.x,
            y: vector.y
        };
    
    }

    /**
     * 
     * @param {Object} data | data object passed to the custom callback, containing info such as index, action, type, etc.
     * @description custom callback function to handle specific actions during the tutorial, can execute JS for a specific action and index such as playing animations
     */
    const customCallbackStage1 = (data) => {
        console.log("customCallbackStage1");
        console.log(data);

        if(data.index === 2) {
            if(data.action === "update") {
                console.log("addClickHereGif");
                addClickHereGif(false, getEntityScreenCoords(7));
            }
            //add event listener to orders message to start next tutorial
            document.getElementById("simulationWindow").addEventListener("click", () => {
                console.log("clicked on simulation canvas");

                console.log("you clicked on an entity");
                console.log(svs.captureEvents.lastClickedEntity);

                if(!svs.captureEvents.lastClickedEntity) return;

                // check if the click was on a produce crate
                // svs.captureEvents.hoverElement -> current element the mouse is over
                // svs.captureEvents.rayCastEntity -> current entity the mouse is over if in the sim
                // svs.captureEvents.lastClickedElement -> last clicked element in the document
                // svs.captureEvents.lastClickedEntity -> last clicked entity in the sim
                if((svs.captureEvents.lastClickedEntity.name === svs.activityData.studentData.equation1.produceA) || (svs.captureEvents.lastClickedEntity.name === svs.activityData.studentData.equation1.produceB)) {
                    // console.log("you clicked on an entity");
                    // console.log(svs.captureEvents.lastClickedEntity);
                    console.log("you clicked on a produce crate");
                    console.log('mofungo uno 1')
                    document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
                    setStartTour(true);
                }
                
                // document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
            }, { once: true });
            //eslint-disable-next-line no-undef
            // engine.entities.get(7).onSelectCallback = () => {
            //     console.log(this);
            //     console.log('you have been clicked');
            //     document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
            //     setStartTour(true);
            //     //eslint-disable-next-line no-undef
            //     engine.entities.get(7).onSelectCallback = null;
            // }
        }
        checkIfTutorialEnded(data);
    }

    const customCallbackStage2 = (data) => {
        console.log("customCallbackStage2");

        if(data.index === 0) {
            if(data.action === "update") {
                console.log("addClickHereGif");
                // addClickHereGif(false, getEntityScreenCoords(7));
                addClickHereGif(false, getEntityScreenCoords(8));
            }

            //add event listener to orders message to start next tutorial
            document.getElementById("simulationWindow").addEventListener("click", () => {
                if((svs.captureEvents.lastClickedEntity.name === svs.activityData.studentData.equation1.produceA) || (svs.captureEvents.lastClickedEntity.name === svs.activityData.studentData.equation1.produceB)) {
                    // console.log("you clicked on an entity");
                    // console.log(svs.captureEvents.lastClickedEntity);
                    console.log("you clicked on a produce crate");
                    console.log('mofungo dos 2')
                    document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
                    setStartTour(true);
                }
            }, { once: true });
            //add event listener to orders message to start next tutorial
            // document.getElementById("orders").addEventListener("click", () => {
            //     console.log("clicked orders");
            //     document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
            // });
            //eslint-disable-next-line no-undef
            // engine.entities.get(7).onSelectCallback = () => {
            //     console.log('you have been clicked 7');
            //     document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
            //     setStartTour(true);
            //     //eslint-disable-next-line no-undef
            //     engine.entities.get(7).onSelectCallback = null;
            //     //eslint-disable-next-line no-undef
            //     engine.entities.get(8).onSelectCallback = null;
            // }

            // //eslint-disable-next-line no-undef
            // engine.entities.get(8).onSelectCallback = () => {
            //     console.log('you have been clicked 8');
            //     document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
            //     setStartTour(true);
            //     //eslint-disable-next-line no-undef
            //     engine.entities.get(7).onSelectCallback = null;
            //     //eslint-disable-next-line no-undef
            //     engine.entities.get(8).onSelectCallback = null;
            // }
        }
        checkIfTutorialEnded(data);
    }

    const customCallbackStage3 = (data) => {
        console.log("customCallbackStage3");

        if(data.index === 0) {
            if(data.action === "update") {

                //add event listener to orders message to start next tutorial
            document.getElementById("simulationWindow").addEventListener("click", () => {

                if((svs.captureEvents.lastClickedEntity.name.includes(svs.activityData.studentData.equation1.produceA) && svs.captureEvents.lastClickedEntity.name !== svs.activityData.studentData.equation1.produceA) || (svs.captureEvents.lastClickedEntity.name.includes(svs.activityData.studentData.equation1.produceB) && svs.captureEvents.lastClickedEntity.name !== svs.activityData.studentData.equation1.produceB)) {
                    // console.log("you clicked on an entity");
                    // console.log(svs.captureEvents.lastClickedEntity);
                    console.log("you clicked on a truck bed crate");
                    console.log('mofungo tres 3')
                    document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
                    setStartTour(true);
                }
            }, { once: true });
                // check what produce is in the truck
                //eslint-disable-next-line no-undef
                // engine.entities.forEach((entity) => {
                //     if(entity.name.includes("produce") && entity.name !== "produce") {
                //         console.log(entity);
                        
                //         addClickHereGif(false, getEntityScreenCoords(entity.id));
                //         //eslint-disable-next-line no-undef
                //         engine.entities.get(entity.id).onSelectCallback = () => {
                //             console.log('you have been clicked here!');
                //             document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
                //             setStartTour(true);
                //             //eslint-disable-next-line no-undef
                //             engine.entities.get(entity.id).onSelectCallback = null;
                //         }
                //     }
                // });
            }

            // document.getElementById("order-message__button--accept").addEventListener("click", () => {
            //     document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
            // });
        }
        checkIfTutorialEnded(data);
    }

    // let customCallbackStage4Called = false;
    const customCallbackStage4 = (data) => {
        console.log("customCallbackStage4");

        if(data.index === 4) {
            if(data.action === "update") {
                console.log("in custom callback stage 4");


                // add event listner to the deliver button


                // if(customCallbackStage4Called) return;
                // customCallbackStage4Called = true;
                // console.log("addClickHereGif in back button");
                // addClickHereGif("phone-ui__button--back"); // add anim to back button

                // document.getElementById("phone-ui__button--back").addEventListener("mouse", (event) => { // add event listener to back button

                //     console.log("clicked back button");
                //     console


                //     document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
                //     // add click anim to the second order
                //     addClickHereGif("phone-ui__body__screen"); // no independent id for the second order, so using phone screen for now //FIX!!!

                //     // add event listener to second order
                //     document.getElementById("phone-ui__body__screen").addEventListener("click", () => {
                //         document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation

                //         setTimeout(() => {
                //             console.log("setTimeout");
                //             // add click anim to accept button
                //             addClickHereGif("order-message__button--accept");

                //             // add event listener to accept button
                //             document.getElementById("order-message__button--accept").addEventListener("click", () => {
                //                 console.log(3);
                //                 document.getElementById("contClickAnimGif").style.display = 'none'; //hide the click animation
                //             }, { once: true });
                //         }, 0);
                //     }, { once: true });
                // }, { once: true });
            }
        }
        checkIfTutorialEnded(data);
    }
   
    return (
        <div id="ordersTutorialContainer">
            { (startTour && (tutorialStage === 1)) && (
			    <GenericTutorial 
                  start={startTour} 
                  onTourEnd={handleTutorialStageEnd}
                  stepsArray={getSteps(1)}
                  customCallback={customCallbackStage1}
              />
			)}
            { (startTour && (tutorialStage === 2)) && (
			    <GenericTutorial 
                  start={startTour} 
                  onTourEnd={handleTutorialStageEnd}
                  stepsArray={getSteps(2)}
                  customCallback={customCallbackStage2}
              />
			)}
            { (startTour && (tutorialStage === 3)) && (
			    <GenericTutorial 
                  start={startTour} 
                  onTourEnd={handleTutorialStageEnd}
                  stepsArray={getSteps(3)}
                  customCallback={customCallbackStage3}
              />
			)}
            { (startTour && (tutorialStage === 4)) && (
			    <GenericTutorial 
                  start={startTour} 
                  onTourEnd={handleTutorialStageEnd}
                  stepsArray={getSteps(4)}
                  customCallback={customCallbackStage4}
              />
			)}
			{/* { (startTour && (tutorialStage === 5 ) && (svs.activityData.studentData.equation1.orderAccepted && svs.activityData.studentData.equation2.orderAccepted)) && (
			    <GenericTutorial 
                start={startTour} 
                onTourEnd={handleTutorialStageEnd}
                stepsArray={getSteps(5)}
            />
			)}  */}
        </div>
    );
};

export default PackingTutorial;